import React, { FunctionComponent, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Link, useRouteMatch, withRouter, useParams,
} from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import Home from '@material-ui/icons/Home';
import FilterList from '@material-ui/icons/FilterList';
import Equalizer from '@material-ui/icons/Equalizer';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import GrainIcon from '@material-ui/icons/Grain';
import GetApp from '@material-ui/icons/GetApp';
import AccountTree from '@material-ui/icons/AccountTree';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import axios from 'axios';
import { useAuth0 } from '../../contexts/auth0-context';
import { ISummary } from './DatasetSingle';
import { ISamples } from './DatasetDetail';
import { nameRegExpFormat, containsSpaceRegExpFormat } from '../../utils';
import {
  SERVER_LOCATION,
  MIN_NAME_CHARACTERS,
} from '../../constants';

const useStyles = makeStyles(theme => ({
  spacing: {
    marginTop: 10,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
  },
  menuTagDivider: {
    marginTop: '10px',
    marginBottom: '30px',
    marginLeft: '5px',
    marginRight: '10px',
  },
  linearProgressBar: {
    width: '90%',
    margin: '20px 0px 0px 8px',
  },
  linearProgressBarText: {
    fontSize: '0.9em',
    padding: '0px 0px 10px 8px',
  },
  linearProgressBarDashedColorPrimary: {
    backgroundColor: '#EEF',
    backgroundImage: 'none',
  },
  linearProgressBarDashed: {
    animation: '',
  },
}));

export interface ITag {
  _id: string,
  date?: number,
  name: string,
  prevTag?: string,
  listOfSampleIds?: string[],
}

export interface IDatasetMenuProps extends RouteComponentProps<any> {
  tags: ITag[],
  summary: ISummary,
  samples: ISamples[],
  listOfActiveSamples: string[],
  onCurrentTagChange: (tag: ITag) => void;
  onDatasetTagsNeedsReload: () => void;
}

const DatasetMenu: FunctionComponent<IDatasetMenuProps> = (props: IDatasetMenuProps) => {
  const classes = useStyles();

  const [openAnalzeList, setOpenAnalyzeList] = useState(false);
  const [currentTag, setCurrentTag] = useState('');
  const [newTagValue, setNewTagValue] = useState('initial-tag');
  const [tagNameError, setTagNameError] = useState(false);
  const [tagNameHelperText, setTagNameHelperText] = useState('Press enter key to create tag');
  const [tagList, setTagList] = useState<any>([]);
  const { getTokenSilently } = useAuth0();
  const { datasetId } = useParams();

  const match = useRouteMatch();

  const {
    tags,
    listOfActiveSamples,
    onDatasetTagsNeedsReload,
    onCurrentTagChange,
    summary,
    samples,
  } = props;

  useEffect(() => {
    if (tags.length > 0) {
      setCurrentTag(tags[0].name);
      setTagList(tags.map(tag => <MenuItem key={tag.name} value={tag.name}>{tag.name}</MenuItem>));
    }
  }, [tags]);

  const handleAnalyzeClick = () => {
    setOpenAnalyzeList(!openAnalzeList);
  };

  const handleNewTagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewTagValue(e.target.value);
  };

  const handleUpdateTags = async () => {
    const auth0token = await getTokenSilently();

    if (listOfActiveSamples.length > 0 && currentTag !== '__undefined__') {
      axios
        .request({
          method: 'post',
          url: `${SERVER_LOCATION}/users/datasets/${datasetId}/tags`,
          headers: {
            Authorization: `Bearer ${auth0token}`,
          },
          data: {
            tag: {
              prevTag: currentTag,
              name: newTagValue,
              listOfSampleIds: listOfActiveSamples,
            },
          },
        })
        .then(() => {
          onDatasetTagsNeedsReload();
          setNewTagValue('');
        });
    } else {
      alert('Error while trying to create new tag');
    }
  };

  const handleSelectTagChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCurrentTag(event.target.value as string);
    const newTag = tags.filter(tag => tag.name === event.target.value)[0];
    onCurrentTagChange(newTag);
  };

  const handleNewTagKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      setTagNameError(false);
      setTagNameHelperText('Press enter key to create tag');
      const tagNames = tags.map(tag => tag.name);
      const tagIsNew = !tagNames.includes(newTagValue);
      if (newTagValue !== ''
        && !nameRegExpFormat.test(newTagValue)
        && tagIsNew
        && newTagValue.length >= MIN_NAME_CHARACTERS
      ) {
        handleUpdateTags();
      } else {
        setTagNameError(true);
        if (containsSpaceRegExpFormat.test(newTagValue)) {
          setTagNameHelperText('Whitespaces are not allowed in tag name');
        } else if (nameRegExpFormat.test(newTagValue)) {
          setTagNameHelperText('No special characters except - and _ are allowed');
        } else if (!tagIsNew) {
          setTagNameHelperText('You can not create two tags with same name');
        } else {
          setTagNameHelperText('Tag name must be at least 3 characters');
        }
      }
    }
  };

  const selectedTagSamples = (): number => {
    const selectedTag = tags.filter(tag => tag.name === currentTag)[0];
    if (selectedTag) {
      return selectedTag.listOfSampleIds?.length as number;
    }
    return samples.length;
  };

  return (
    <Grid
      container
      alignContent="stretch"
      direction="column"
      justify="space-between"
      className={clsx(classes.spacing)}
    >
      <List component="nav" aria-label="main mailbox folders">
        <Link to={`${match.url}`}>
          <ListItem button>
            <ListItemIcon>
              <Home />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
        </Link>
        <Link to={`${match.url}/metadata`}>
          <ListItem button>
            <ListItemIcon>
              <AccountTree />
            </ListItemIcon>
            <ListItemText primary="Metadata" />
          </ListItem>
        </Link>
        <ListItem button onClick={handleAnalyzeClick}>
          <ListItemIcon>
            <FilterList />
          </ListItemIcon>
          <ListItemText primary="Analyze & Filter" />
          {openAnalzeList ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openAnalzeList} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Link to={`${match.url}/histogram`}>
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <Equalizer />
                </ListItemIcon>
                <ListItemText primary="Histogram" />
              </ListItem>
            </Link>
            <Link to={`${match.url}/sampling`}>
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <GrainIcon />
                </ListItemIcon>
                <ListItemText primary="Sampling" />
              </ListItem>
            </Link>
          </List>
        </Collapse>
        <Link to={`${match.url}/download`}>
          <ListItem button>
            <ListItemIcon>
              <GetApp />
            </ListItemIcon>
            <ListItemText primary="Download" />
          </ListItem>
        </Link>
      </List>
      <Divider className={clsx(classes.menuTagDivider)} />
      {(summary.nSamples as number) > 0
        && (currentTag === '__undefined__' ? (
          <Grid item>
            <TextField
              id="standard-basic"
              label="Enter a new tag"
              value={newTagValue}
              onChange={handleNewTagChange}
            />
            <Button onClick={handleUpdateTags}>
              Create a tag for the dataset
            </Button>
          </Grid>
        ) : (
          <div>
            <Grid item>
              <FormControl className={clsx(classes.formControl)}>
                <InputLabel id="select-tag-label">Select Active Tag</InputLabel>
                <Select
                  labelId="select-tag-label"
                  value={currentTag}
                  onChange={handleSelectTagChange}
                >
                  {tagList}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <LinearProgress
                classes={{
                  root: classes.linearProgressBar,
                  dashedColorPrimary: classes.linearProgressBarDashedColorPrimary,
                  dashed: classes.linearProgressBarDashed,
                }}
                variant="buffer"
                value={(100 * listOfActiveSamples.length) / samples.length}
                valueBuffer={100 * selectedTagSamples() / samples.length}
              />
            </Grid>
            <Grid item className={clsx(classes.linearProgressBarText)}>
              You work with
              {' '}
              {listOfActiveSamples.length}
              {' / '}
              {samples.length}
              {' '}
              samples
            </Grid>
            <Grid item>
              <FormControl className={clsx(classes.formControl)}>
                <TextField
                  id="standard-basic"
                  label="Create a new tag"
                  value={newTagValue}
                  onChange={handleNewTagChange}
                  onKeyPress={handleNewTagKeyPress}
                  error={tagNameError}
                  helperText={tagNameHelperText}
                />
              </FormControl>
            </Grid>
          </div>
        ))}
    </Grid>
  );
};

export default withRouter(DatasetMenu);
