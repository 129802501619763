/* eslint-disable import/prefer-default-export */
import React, { FunctionComponent, useState } from 'react'; // importing FunctionComponent
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import { makeStyles, withStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios, { AxiosResponse } from 'axios';
import moment from 'moment';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { useAuth0 } from '../contexts/auth0-context';

import {
  TOOLTIP_DATASET_TEXT,
  TOOLTIP_TOKEN_TEXT,
  SERVER_LOCATION,
  TOOLTIP_FILTER_STRENGTH_TEXT,
  TOOLTIP_USE_CASE_TEXT,
  MAX_FILE_SIZE_IN_GB,
  RESULTS_SUCCESS_TEXT,
  RESULTS_FAILURE_TEXT,
  ToGB,
  ETA_SMOOTHING_FACTOR,
} from '../constants';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: '#F4F4F3',
    borderRadius: '5px',
    padding: '15px',
    margin: '25px 35px',
    boxShadow: '2px 2px 15px -5px',
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  resultsText: {
    fontSize: 'smaller',
    marginBottom: theme.spacing(2),
  },
  uploadingText: {
    fontSize: 'smaller',
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  fileSelected: {
    backgroundColor: '#d55f42',
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  tooltips: {
    paddingTop: '10px',
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 280,
  },
}));

export const UploadForm: FunctionComponent = () => {
  const classes = useStyles();

  const [file, setFile] = useState('');
  const [filename, setFilename] = useState('');
  const [showprocessing, setShowprocessing] = useState(false);
  const [showresults, setShowresults] = useState(false);
  const [resultstext, setResultstext] = useState('');
  const [uploadprogress, setUploadprogress] = useState(0);
  const [remainingtime, setRemainingtime] = useState('');
  const [filterstrength, setFilterstrength] = useState('reduce-cost');
  const [usecase, setUsecase] = useState('classification');
  const [mail, setMail] = useState('');
  const [token, setToken] = useState('');
  const [acceptterms, setAcceptterms] = useState(false);

  const { getIdTokenClaims, getTokenSilently } = useAuth0();

  // const [jobId, setJobId] = useState('');

  function checkSuccess(jobId: string, response: AxiosResponse) {
    if (response) {
      axios
        .post(`${SERVER_LOCATION}/verify`, {
          params: {
            jobId,
          },
        })
        .then((res: AxiosResponse) => {
          const { status } = res.data;
          if (status) {
            if (status === 'valid') {
              console.log('Successful verification of the upload');
              setResultstext(RESULTS_SUCCESS_TEXT);
            } else {
              console.log('Error while verifying succesful upload');
              setResultstext(RESULTS_FAILURE_TEXT);
            }
            setShowprocessing(false);
            setShowresults(true);
            setFile('');
            setUploadprogress(0);
          }
        });
    } else {
      throw new Error(response);
    }
  }

  function uploadFileWithSignedUrl(jobId: string, url: string, file: any) {
    const startTime = Date.now();
    let totalTime = 0;

    axios
      .put(url, file, {
        onUploadProgress: (p) => {
          const currentTime = Date.now();
          const elapsedTime = currentTime - startTime;
          const fraction = p.loaded / p.total;
          if (totalTime > 0) {
            totalTime = ETA_SMOOTHING_FACTOR * totalTime
              + (1 - ETA_SMOOTHING_FACTOR) * (1.0 / fraction) * elapsedTime;
          } else {
            totalTime = (1.0 / fraction) * elapsedTime;
          }
          const estimatedTime = totalTime - elapsedTime;
          const remainingTime = moment().add(estimatedTime, 'ms');
          setUploadprogress(Math.floor(100.0 * fraction));
          setRemainingtime(`ETA: ${remainingTime.fromNow(true)}`);
        },
        headers: { 'content-type': 'application/octet-stream' },
      })
      .then((res) => {
        if (!res) {
          throw new Error(res);
        } else {
          checkSuccess(jobId, res);
        }
      });
  }

  async function submitJob(response: AxiosResponse) {
    if (response.data.status === 'valid') {
      const data = new FormData();
      data.append('mail', mail);
      data.append('token', token);
      data.append('acceptterms', acceptterms.toString());
      data.append('filterstrength', filterstrength);
      data.append('usecase', usecase);
      data.append('filename', filename);

      setShowprocessing(true);

      const auth0token = await getTokenSilently();

      axios
        .request({
          method: 'post',
          url: `${SERVER_LOCATION}/uploadsigned`,
          data,
          headers: {
            Authorization: `Bearer ${auth0token}`,
          },
        })
        .then((res) => {
          uploadFileWithSignedUrl(res.data.jobId, res.data.signedurl, file);
        })
        .catch((err) => {
          console.error('Error during upload of dataset!');
          throw new Error(err);
        });
    } else {
      alert('The token you entered is not valid');
    }
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const auth0token = await getTokenSilently();

    if (mail !== '' && token !== '' && acceptterms && file !== '') {

      await axios
        .get(`${SERVER_LOCATION}/tokencheck`, {
          headers: {
            Authorization: `Bearer ${auth0token}`,
          },
          params: {
            token,
          },
        })
        .then(submitJob)
        .catch((err) => {
          console.log(err.response.data);
          console.log(err);
        });
    } else {
      let msg = 'Form incomplete, missing:';
      if (!acceptterms) {
        msg += ' terms not accepted';
      }
      if (file.length <= 0) {
        msg += ' file not selected';
      }
      msg += '.';
      // TODO: remove alert
      alert(msg);
    }
  };

  const fileChange = (event: any) => {
    const fileSize: number = event.target.files[0]
      ? event.target.files[0].size
      : null;

    if (fileSize == null) {
      setFile('');
      setFilename('');
    } else if (ToGB(fileSize) > MAX_FILE_SIZE_IN_GB) {
      alert(
        `Max dataset size is ${MAX_FILE_SIZE_IN_GB} GB. Your file has a size of ${ToGB(
          fileSize,
        )} GB`,
      );
      setFile('');
      setFilename('');
    } else {
      setFile(event.target.files[0]);
      setFilename(event.target.files[0].name);
    }
    event.preventDefault();
  };

  const filterstrengthChange = (event: any) => {
    setFilterstrength(event.target.value);
    event.preventDefault();
  };

  const mailChange = (event: any) => {
    setMail(event.target.value);
  };

  const tokenChange = (event: any) => {
    setToken(event.target.value);
  };

  const usecaseChange = (event: any) => {
    setUsecase(event.target.value);
    event.preventDefault();
  };

  const accepttermsChange = (event: any) => {
    setAcceptterms(event.target.checked);
  };

  const BigTextTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);

  return (
    <Grid item xs={12} className={clsx(classes.container)}>
      <Grid item xs={12}>
        <h2 className={clsx(classes.textField)}>Filter your dataset</h2>
      </Grid>
      <Grid item xs={12}>
        <span className={clsx(classes.textField)}>
          Don&apos;t have a dataset to filter but still want to see the results?
          <br />
          <a className={clsx(classes.textField)} href="https://www.whattolabel.com/datasets">
            Checkout filtered dataset section
          </a>
        </span>
      </Grid>
      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <Grid item xs={11}>
            <TextField
              id="mail"
              label="E-Mail"
              name="mail"
              type="email"
              className={clsx(classes.textField, classes.dense)}
              margin="dense"
              variant="outlined"
              value={mail}
              onChange={mailChange}
              fullWidth
              required
            />
          </Grid>
          <Grid container direction="row" alignItems="center" justify="center">
            <Grid item xs={8}>
              <TextField
                id="token"
                label="Token"
                name="token"
                type="text"
                className={clsx(classes.textField, classes.dense)}
                margin="dense"
                variant="outlined"
                value={token}
                onChange={tokenChange}
                required
              />
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={2} className={clsx(classes.tooltips)}>
              <BigTextTooltip title={TOOLTIP_TOKEN_TEXT} placement="right">
                <IconButton aria-label="help">
                  <HelpIcon />
                </IconButton>
              </BigTextTooltip>
            </Grid>
          </Grid>

          <Grid container direction="row" alignItems="center" justify="center">
            <Grid item xs={8}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="filter-strength">
                  Filter Strength
                </InputLabel>
                <Select
                  value={filterstrength}
                  onChange={filterstrengthChange}
                  inputProps={{ name: 'filter', id: 'filter-strength' }}
                  name="filterstrength"
                >
                  <MenuItem value="reduce-cost">Reduce Annotation Costs</MenuItem>
                  <MenuItem value="reduce-overfitting">Reduce Overfitting</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={2} className={clsx(classes.tooltips)}>
              <BigTextTooltip
                title={TOOLTIP_FILTER_STRENGTH_TEXT}
                placement="right"
              >
                <IconButton aria-label="help">
                  <HelpIcon />
                </IconButton>
              </BigTextTooltip>
            </Grid>
          </Grid>

          <Grid container direction="row" alignItems="center" justify="center">
            <Grid item xs={8}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="use-case">
                  What do you want to use the data for?
                </InputLabel>
                <Select
                  value={usecase}
                  onChange={usecaseChange}
                  inputProps={{ name: 'usecase', id: 'use-case' }}
                  name="usecase"
                >
                  <MenuItem value="classification">Classification</MenuItem>
                  <MenuItem value="detection">Object Detection</MenuItem>
                  <MenuItem value="segmentation">Segmentation</MenuItem>
                  <MenuItem value="generative">GANs (experimental)</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={2} className={clsx(classes.tooltips)}>
              <BigTextTooltip title={TOOLTIP_USE_CASE_TEXT} placement="right">
                <IconButton aria-label="help">
                  <HelpIcon />
                </IconButton>
              </BigTextTooltip>
            </Grid>
          </Grid>
          <Grid container direction="row" alignItems="center" justify="center">
            <Grid
              container
              item
              xs={10}
              direction="row"
              alignItems="center"
              justify="flex-start"
            >
              <Grid item>
                <Button
                  size="small"
                  component="label"
                  variant="contained"
                  disabled={showprocessing}
                  className={clsx(classes.textField, {
                    [classes.fileSelected]: file !== '',
                  })}
                >
                  Upload Dataset
                  <input
                    type="file"
                    name="file"
                    accept=".zip, .tar"
                    onChange={fileChange}
                    style={{ display: 'none' }}
                  />
                </Button>
              </Grid>
              <Grid item>{filename}</Grid>
            </Grid>
            <Grid item xs={2} className={clsx(classes.tooltips)}>
              <BigTextTooltip title={TOOLTIP_DATASET_TEXT} placement="right">
                <IconButton aria-label="help">
                  <HelpIcon />
                </IconButton>
              </BigTextTooltip>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="flex-start"
          >
            <Grid item>
              <FormControlLabel
                name="acceptterms"
                control={<Checkbox color="primary" />}
                label=""
                value={acceptterms}
                onChange={accepttermsChange}
                labelPlacement="end"
                className={clsx(classes.textField)}
              />
            </Grid>
            <Grid item>
              <Link href="/terms_and_conditions.pdf" target="_blank">
                Terms & Conditions
              </Link>
            </Grid>
          </Grid>
          <Divider variant="middle" />
          <Grid item xs={12}>
            <Button
              size="medium"
              disabled={showprocessing}
              variant="text"
              className={clsx(classes.submitButton)}
              component="label"
            >
              Submit
              <input type="submit" value="Submit" style={{ display: 'none' }} />
            </Button>
          </Grid>
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
            spacing={4}
          >
            <Grid item xs={12} />
            {showprocessing && (
              <Grid container direction="column" alignItems="center">
                <Grid item xs={4}>
                  <CircularProgress />
                </Grid>
                <Grid item xs={12} className={clsx(classes.uploadingText)}>
                  {uploadprogress}
% Uploading dataset...
                  {remainingtime}
                </Grid>
              </Grid>
            )}
            {showresults && (
              <Grid item xs={10} className={clsx(classes.resultsText)}>
                {resultstext}
              </Grid>
            )}
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};
