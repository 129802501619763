/* eslint-disable no-underscore-dangle */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  FunctionComponent, useEffect, useState, useRef,
} from 'react';
import { useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import * as d3 from 'd3';
import Slider from '@material-ui/core/Slider';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import axios, { AxiosResponse } from 'axios';
import { useAuth0 } from '../../contexts/auth0-context';
import { ISampling, ISamplingSummary } from './DatasetSampling';
import { ITag } from './DatasetMenu';
import {
  SERVER_LOCATION,
} from '../../constants';

const useStyles = makeStyles(() => ({
  copyright: {
    textAlign: 'right',
    padding: '0.5em',
  },
  version: {
    textAlign: 'left',
    padding: '0.5em',
  },
  spacing: {
    width: 'inherit',
  },
  text: {
    color: '#FFFFFF',
  },

  histogramContainer: {
    display: 'inline-block',
    position: 'relative',
    width: '100%',
  },
  slider: {
    padding: '0% 10%',
  },
}));

export interface ISamplingProcess {
      tag: ITag,
      samplingSummaries: ISamplingSummary[],
      listOfActiveSamples: string[],
      onListOfActiveSamplesChange: (ev: string[]) => void,
  }


const SamplingProcess: FunctionComponent<ISamplingProcess> = (props: ISamplingProcess) => {
  const classes = useStyles();
  const ref = useRef(null);

  const { datasetId } = useParams();

  const {
    tag, samplingSummaries, listOfActiveSamples, onListOfActiveSamplesChange,
  } = props;
  const [samplings, setSamplings] = useState<ISampling[]>([]);

  const [sliderValue, setSliderValue] = useState(100);
  const [maxSliderVal, setMaxSliderVal] = useState(100);
  const [samplingList, setSamplingList] = useState<any>([]);
  const [currentSampling, setCurrentSampling] = useState('');
  const [plotNeedsUpdate, setPlotNeedsUpdate] = useState(false);

  const { isLoading, getTokenSilently } = useAuth0();

  const w = 200;
  const h = 140;
  const margin = 20;

  useEffect(() => {
    async function fetchData() {
      const activeSamplingSummary = samplingSummaries.filter(
        elem => elem.name === currentSampling,
      )[0];

      const auth0token = await getTokenSilently();
      axios.get(`${SERVER_LOCATION}/users/datasets/${datasetId}/samplings/${activeSamplingSummary._id}`, {
        headers: {
          Authorization: `Bearer ${auth0token}`,
        },
      }).then((res: AxiosResponse) => {
        setSamplings(res.data);
        setPlotNeedsUpdate(true);
      });
    }
    if (!isLoading && currentSampling !== '' && samplingSummaries.length > 0) {
      fetchData();
    }
  }, [isLoading, samplingSummaries, currentSampling]);

  useEffect(() => {
    if (listOfActiveSamples.length > 0 && samplings.length > 0 && plotNeedsUpdate) {
      setPlotNeedsUpdate(false);
      const filteredSamplings = samplings.filter(elem => listOfActiveSamples.includes(elem.sample));
      const maxVal = filteredSamplings.length;
      setMaxSliderVal(maxVal);
      if (maxVal !== maxSliderVal) {
        setSliderValue(maxVal);
      }

      const data = filteredSamplings.map((elem, idx) => {
        const obj = { idx, val: elem.score };
        return obj;
      });

      let g = d3.select(ref.current);
      g.selectAll('g').remove();
      g.selectAll('path').remove();
      g.selectAll('text').remove();

      g = d3.select(ref.current)
        .attr('preserveAspectRatio', 'xMinYMin meet')
        .attr('viewBox', '0 0 200 140')
        .classed('svg-content', true);

      const yScale = d3.scaleLinear()
        .domain([1.0, 0.0])
        .range([0, h - 2 * margin]);

      const xScale = d3.scaleLinear()
        .domain([0, data.length])
        .range([0, w - 2 * margin]);

      g.append('path')
        .datum(data)
        .attr('fill', 'none')
        .attr('stroke', 'steelblue')
        .attr('stroke-width', 1.5)
        .attr('stroke-linejoin', 'round')
        .attr('stroke-linecap', 'round')
        .attr('d', d3.line<any>()
          .x(d => xScale(d.idx))
          .y(d => yScale(d.val)))
        .attr('transform', `translate(${margin}, ${margin})`);

      g.append('text')
        .attr('x', (w / 2))
        .attr('y', 15)
        .attr('text-anchor', 'middle')
        .style('font-size', '9px')
        .style('text-decoration', 'underline')
        .text(currentSampling);

      g.append('g')
        .attr('transform', `translate(${margin}, ${margin})`)
        .call(d3.axisLeft(yScale).ticks(5, 's'))
        .style('font-size', '7px');

      g.append('g')
        .attr('transform', `translate(${margin}, ${h - margin})`)
        .call(d3.axisBottom(xScale))
        .style('font-size', '7px');
    }
  }, [samplings, plotNeedsUpdate, currentSampling, listOfActiveSamples.length]);

  useEffect(() => {
    if (samplingSummaries.length > 0) {
      const filteredSamplingSummaries = samplingSummaries.filter(elem => tag._id === elem.tag);
      if (filteredSamplingSummaries.length > 0) {
        setCurrentSampling(filteredSamplingSummaries[0].name);
        setSamplingList(filteredSamplingSummaries.map((sampling) => {
          const val = sampling.name;
          return <MenuItem key={val} value={val}>{val}</MenuItem>;
        }));
      } else {
        setSamplingList([]);
        setCurrentSampling('');
        setSamplings([]);
      }
    }
  }, [tag]);

  useEffect(() => setPlotNeedsUpdate(true), []);

  const handleSliderChange = (event: any, newVal: number | number[]) => {
    const value = newVal as number;
    setSliderValue(value);
    const filteredSamplings = samplings.filter((elem, idx) => idx < value);
    const newActiveSamplesList = filteredSamplings.map(elem => elem.sample);
    onListOfActiveSamplesChange(newActiveSamplesList);
  };

  const handleCurrentSamplingSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCurrentSampling(event.target.value as string);
  };

  return (
    <Grid
      container
      alignContent="stretch"
      direction="column"
      justify="space-between"
    >
      <Grid item>
        { samplings.length > 0 ? (
          <Grid item xs={12} md={10} lg={10} xl={6}>
            <Grid item>
              <FormControl>
                <InputLabel id="select-sampling-label">Sampling</InputLabel>
                <Select
                  labelId="select-sampling-label"
                  value={currentSampling}
                  onChange={handleCurrentSamplingSelectChange}
                >
                  {samplingList}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <svg ref={ref} className={clsx(classes.histogramContainer)} />
            </Grid>
            <Grid item className={clsx(classes.slider)}>
              <Slider
                value={sliderValue}
                onChange={handleSliderChange}
                valueLabelDisplay="auto"
                aria-labelledby="continuous-slider"
                max={maxSliderVal}
              />
            </Grid>
          </Grid>
        ) : (
          <p>No sampling available for this tag.</p>
        ) }
      </Grid>
    </Grid>
  );
};

export default SamplingProcess;
