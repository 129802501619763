import React, { FunctionComponent } from 'react';
import Grid from '@material-ui/core/Grid';
import { useAuth0 } from '../contexts/auth0-context';

const Home: FunctionComponent = () => {
  const { isAuthenticated } = useAuth0();

  return (
    <Grid
      container
      alignContent="center"
      direction="column"
      justify="center"
    >
      <Grid item>
        <h3>Welcome to the data preparation cockpit</h3>
      </Grid>
      <Grid item>
        { isAuthenticated ? (
          <div>
            <p>
              Click on
              {' '}
              <b>my datasets</b>
              {' '}
              in the menu (top right corner) to get started.
            </p>
            <p>
              New to the platform? Check out our
              {' '}
              <a
                href="https://www.notion.so/whattolabel/WhatToLabel-Documentation-28e645f5564a453e807d0a384a4e6ea7"
                rel="noopener noreferrer"
                target="_blank"
              >
                documentation and tutorials
              </a>
            </p>
          </div>
        ) : (
          <p>
            You need first to sign in to use the app.
            {' '}
            Click on the button on the top right corner.
          </p>
        )}
      </Grid>
    </Grid>
  );
};

export default Home;
