/* eslint-disable react/no-array-index-key */
import React, { FunctionComponent, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import axios, { AxiosResponse } from 'axios';
import { useAuth0 } from '../contexts/auth0-context';
import {
  SERVER_LOCATION,
} from '../constants';


const Preferences: FunctionComponent = () => {
  const { isAuthenticated, isLoading, getTokenSilently } = useAuth0();

  const [listOfTokens, setListOfTokens] = useState<string[]>([]);
  const [preferencesNeedReload, setPreferencesNeedReload] = useState<boolean>(true);

  useEffect(() => {
    async function fetchData() {
      const auth0token = await getTokenSilently();
      axios
        .get(`${SERVER_LOCATION}/users/tokens`, {
          headers: {
            Authorization: `Bearer ${auth0token}`,
          },
        })
        .then((res: AxiosResponse) => {
          setListOfTokens([res.data]);
          setPreferencesNeedReload(false);
        });
    }
    if (!isLoading && listOfTokens.length === 0) {
      fetchData();
    }
  }, [getTokenSilently, isLoading, preferencesNeedReload]);

  const formatTokenList = (tokenList: string[]) => {
    if (tokenList === undefined) {
      const data = <p>No tokens found</p>;
      return data;
    }
    const data = tokenList.map((token: string, idx: number) => (
      <Grid item key={idx}>
        <p key={idx}>{token}</p>
      </Grid>
    ));
    return data;
  };

  return (
    <Grid
      container
      alignContent="center"
      direction="column"
      justify="center"
    >
      <Grid item>
        <h3>Preferences</h3>
      </Grid>
      <Grid item>
        { isAuthenticated ? (
          <div>
            <h4>API Token</h4>
            {formatTokenList(listOfTokens)}
          </div>
        ) : (
          <p>
            You need first to sign in to use the app.
            {' '}
            Click on the button on the top right corner.
          </p>
        )}
      </Grid>
    </Grid>
  );
};

export default Preferences;
