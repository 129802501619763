import React, { FunctionComponent } from 'react';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { UploadForm } from './UploadForm';
import { Footer } from './Footer';
import { HowItWorks } from './HowItWorks';


const useStyles = makeStyles((theme: Theme) => ({
  title: {
    color: '#FFFFFF',
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    color: '#EEEEEE',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontWeight: 'normal',
  },
}));

const Filter: FunctionComponent = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="center"
      className="App-container"
    >
      <Grid item xs={10}>
        <h1 className={clsx(classes.title)}>
          WhatToLabel application
        </h1>
      </Grid>
      <Grid item xs={10}>
        <h4 className={clsx(classes.subtitle)}>
          Filter and analyze your image dataset
        </h4>
      </Grid>
      <Grid item xs={12} sm={10} md={8} lg={6}>
        <UploadForm />
      </Grid>
      <Grid item xs={12} sm={10} md={10}>
        <HowItWorks />
        <Footer />
      </Grid>
    </Grid>
  );
};

export default Filter;
