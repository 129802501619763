/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable import/prefer-default-export */

import React, { FunctionComponent } from 'react'; // importing FunctionComponent
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { VERSION } from '../constants';

const useStyles = makeStyles(() => ({
  copyright: {
    textAlign: 'right',
    padding: '0.5em',
  },
  version: {
    textAlign: 'left',
    padding: '0.5em',
  },
  spacing: {
    marginTop: 10,
  },
  text: {
    color: '#FFFFFF',
  },
}));

export const Footer: FunctionComponent = () => {
  const classes = useStyles();
  const year: number = new Date().getFullYear();

  return (
    <Grid
      container
      alignContent="stretch"
      direction="row"
      justify="space-between"
      className={clsx(classes.spacing)}
    >
      <Grid item xs={6} className={clsx(classes.version)}>
        <Typography variant="body2" className={clsx(classes.text)}>
          Version {VERSION}
        </Typography>
      </Grid>
      <Grid item xs={6} className={clsx(classes.copyright)}>
        <Typography variant="body2" className={clsx(classes.text)}>
          ©WhatToLabel {year}
        </Typography>
      </Grid>
    </Grid>
  );
};
