/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import axios, { AxiosResponse } from 'axios';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DatasetCreate from './mydatasets/DatasetCreate';
import DatasetSingle, { ISummary } from './mydatasets/DatasetSingle';
import { useAuth0 } from '../contexts/auth0-context';
import {
  SERVER_LOCATION,
} from '../constants';

const useStyles = makeStyles(() => ({
  copyright: {
    textAlign: 'right',
    padding: '0.5em',
  },
  version: {
    textAlign: 'left',
    padding: '0.5em',
  },
  spacing: {
    marginTop: 10,
  },
  text: {
    color: '#FFFFFF',
  },
  dataset: {
    display: 'grid',
  },
  root: {
    flexGrow: 1,
  },
  paper: {
  },
  createDataset: {
    paddingLeft: 10,
  },
  datasetContainer: {
    padding: 10,
  },
}));

const Datasets: FunctionComponent = () => {
  const classes = useStyles();
  const { isLoading, getTokenSilently } = useAuth0();

  const [datasetsNeedReload, setDatasetsNeedReload] = useState(true);
  const [listOfDatasetComps, setListOfDatasetComps] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [datasetToDelete, setDatasetToDelete] = useState('');
  const [deleteInProcess, setDeleteInProcess] = useState(false);


  const deleteDataset = async (datasetId: string) => {
    const auth0token = await getTokenSilently();
    axios.delete(`${SERVER_LOCATION}/users/datasets/${datasetId}`, {
      headers: {
        Authorization: `Bearer ${auth0token}`,
      },
    }).then(() => {
      setDatasetsNeedReload(true);
      setDatasetToDelete('');
      setDeleteInProcess(false);
      setOpenDeleteDialog(false);
    });
  };

  const handleDatasetDelete = async (datasetId: string) => {
    setDatasetToDelete(datasetId);
    setOpenDeleteDialog(true);
  };

  const handleClickCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleClickDeleteDialog = () => {
    setDeleteInProcess(true);
    deleteDataset(datasetToDelete);
  };

  useEffect(() => {
    async function fetchData() {
      const auth0token = await getTokenSilently();
      axios
        .get(`${SERVER_LOCATION}/users/datasets`, {
          headers: {
            Authorization: `Bearer ${auth0token}`,
          },
        })
        .then((res: AxiosResponse) => {
          const data = res.data.map((x: ISummary, idx: number) => (
            <Grid
              item
              xs={4}
              key={`grid_${idx}`}
              className={clsx(classes.dataset)}
            >
              <DatasetSingle
                _id={x._id}
                key={idx}
                name={x.name}
                type={x.type}
                nSamples={x.nSamples}
                sizeInBytes={x.sizeInBytes}
                createdAt={x.createdAt}
                lastModifiedAt={x.lastModifiedAt}
                onDatasetDelete={handleDatasetDelete}
              />
            </Grid>
          ));
          setListOfDatasetComps(data);
          setDatasetsNeedReload(false);
        });
    }
    if (!isLoading && datasetsNeedReload) {
      fetchData();
    }
  }, [getTokenSilently, isLoading, datasetsNeedReload]);


  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="flex-start"
      className={clsx(classes.root, classes.datasetContainer)}
    >
      <Grid
        item
        xs={12}
        lg={12}
        xl={8}
      >
        <Grid
          container
          className={clsx(classes.spacing)}
          spacing={2}
          direction="row"
          alignItems="stretch"
        >
          <Grid
            item
            xs={4}
            className={clsx(classes.dataset)}
          >
            <DatasetCreate key="create" />
          </Grid>
          {listOfDatasetComps === '' && <CircularProgress />}
          {listOfDatasetComps !== '' && listOfDatasetComps.length === 0
            ? (
              <p className={clsx(classes.createDataset)}>You have no datasets. Create one now.</p>
            ) : listOfDatasetComps}
        </Grid>
      </Grid>
      <Dialog
        open={openDeleteDialog}
        onClose={handleClickCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deletion of dataset cannot be undone.
            Click DELETE if you are sure that you want to delete the dataset!
          </DialogContentText>
          {deleteInProcess && <CircularProgress />}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClickDeleteDialog}
            variant="outlined"
            color="primary"
          >
            DELETE
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default Datasets;
