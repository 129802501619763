import React, { FunctionComponent, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import axios, { AxiosResponse } from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { useAuth0 } from '../../contexts/auth0-context';
import { displayJson } from '../../utils';
import { ISummary } from './DatasetSingle';

import {
  SERVER_LOCATION,
} from '../../constants';

const useStyles = makeStyles(() => ({
  copyright: {
    textAlign: 'right',
    padding: '0.5em',
  },
  version: {
    textAlign: 'left',
    padding: '0.5em',
  },
  spacing: {
    marginTop: 10,
  },
  text: {
    color: '#FFFFFF',
  },
  metaData: {
    paddingBottom: 20,
  },
  imgPaper: {
    width: 'fit-content',
    height: 'fit-content',
    marginBottom: 15,
    padding: 3,
    backgroundColor: '#f5f5f5',
  },
  img: {
    maxWidth: '300px',
  },
  uploadButton: {
    marginTop: 10,
  },
}));

export interface ICustomMetaData {
  fileName: string,
  metaName: string,
  value: number | number[],
}

interface IDatasetMetaData {
  onHandleDatasetSummaryReloadChange: (ev: any) => void,
  summary: ISummary,
}

const DatasetMetadata: FunctionComponent<IDatasetMetaData> = (props: IDatasetMetaData) => {
  const classes = useStyles();
  const { datasetId } = useParams();
  const [metaData, setMetaData] = useState<ICustomMetaData>({} as ICustomMetaData);
  const [newMetaData, setNewMetaData] = useState<Object>({});
  const [newMetaDataFname, setNewMetaDataFname] = useState('');
  const [metaDataNeedsReload, setMetaDataNeedsReload] = useState(true);
  const [openWrongMetaSyntaxDialog, setOpenWrongMetaSyntaxDialog] = useState(false);

  const { isLoading, getTokenSilently } = useAuth0();

  const { onHandleDatasetSummaryReloadChange, summary } = props;

  useEffect(() => {
    async function fetchData() {
      const auth0token = await getTokenSilently();
      axios
        .get(`${SERVER_LOCATION}/metadata`, {
          headers: {
            Authorization: `Bearer ${auth0token}`,
          },
          params: {
            datasetId,
          },
        })
        .then((res: AxiosResponse) => {
          setMetaData(res.data);
          setMetaDataNeedsReload(false);
        });
    }
    if (!isLoading && datasetId && metaDataNeedsReload) {
      fetchData();
    }
  }, [getTokenSilently, isLoading, datasetId, metaDataNeedsReload]);

  async function uploadNewMetaData(data: Object) {
    const auth0token = await getTokenSilently();
    axios.post(`${SERVER_LOCATION}/metadata`, data, {
      headers: {
        Authorization: `Bearer ${auth0token}`,
      },
    }).then((res: AxiosResponse) => {
      setNewMetaData({});
      setNewMetaDataFname('');
      setMetaDataNeedsReload(true);
      onHandleDatasetSummaryReloadChange(true);
    });
    return true;
  }

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.addEventListener('load', (e) => {
      const filereader = e.target as FileReader;
      const textFromFile = filereader.result as string;
      try {
        const jsonText = JSON.parse(textFromFile);
        setNewMetaData(jsonText);
      } catch (error) {
        if (error instanceof SyntaxError) {
          console.log('Syntax error. Provided file is no json.');
          setOpenWrongMetaSyntaxDialog(true);
        }
      }
    });
    if (file) {
      reader.readAsText(file);
      setNewMetaDataFname(file.name);
    }
  };

  const handleUploadMetaDataClick = () => {
    uploadNewMetaData(newMetaData);
  };

  const handleCloseWrongMetaSyntaxDialog = () => {
    setOpenWrongMetaSyntaxDialog(false);
  };

  return (
    <Grid
      container
      alignContent="flex-start"
      direction="column"
      justify="flex-start"
      className={clsx(classes.spacing)}
    >
      <Grid item xs={12}>
        { summary.name && (
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/datasets">
              My Datasets
            </Link>
            <Link color="inherit" href={`/datasets/${summary._id}`}>
              {summary.name}
            </Link>
            <Typography color="textPrimary">Metadata</Typography>
          </Breadcrumbs>
        )}
      </Grid>
      <Grid item>
        <h3>Current Metadata</h3>
      </Grid>
      { Object.keys(metaData).length > 0
        ? (
          <div>
            <Grid item>
              Below you find the meta data from a random sample from your dataset.
            </Grid>
            <Grid item className={clsx(classes.metaData)}>
              {displayJson(JSON.stringify(metaData))}
            </Grid>
          </div>
        ) : (
          <div>
            <Grid item>
              <p>No metadata found for your dataset. Click the button to add some.</p>
              <p>
                Your dataset id:
                {' '}
                <b>{datasetId}</b>
              </p>
              <p>Example of expected metadata json format:</p>
            </Grid>
            <Paper elevation={3} className={clsx(classes.imgPaper)}>
              <Grid item>
                <img
                  className={clsx(classes.img)}
                  alt="custom meta json example"
                  src="/custom-meta-json-example.jpg"
                />
              </Grid>
            </Paper>
          </div>
        )}

      <Grid item>
        <Button
          size="small"
          component="label"
          variant="contained"
        >
          Select new metadata
          <input
            type="file"
            name="file"
            accept=".json"
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
        </Button>
        {newMetaDataFname}
      </Grid>
      <Grid item>
        <Button color="primary" onClick={handleUploadMetaDataClick} variant="outlined" className={clsx(classes.uploadButton)}>
          Upload
        </Button>
      </Grid>
      <Dialog
        open={openWrongMetaSyntaxDialog}
        onClose={handleCloseWrongMetaSyntaxDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Syntax Error</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The meta data file you provided is not a valid json file.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWrongMetaSyntaxDialog} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default DatasetMetadata;
