/* eslint-disable no-underscore-dangle */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable arrow-body-style */
import React, { FunctionComponent, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { useAuth0 } from '../../contexts/auth0-context';
import { ISummary } from './DatasetSingle';
import { ITag } from './DatasetMenu';
import { nameRegExpFormat, containsSpaceRegExpFormat } from '../../utils';
import {
  SAMPLING_SERVER_LOCATION,
  MIN_NAME_CHARACTERS,
} from '../../constants';

const useStyles = makeStyles(theme => ({
  copyright: {
    textAlign: 'right',
    padding: '0.5em',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
  },
  version: {
    textAlign: 'left',
    padding: '0.5em',
  },
  spacing: {
    marginTop: 10,
  },
  text: {
    color: '#FFFFFF',
  },
  dataset: {
    padding: 10,
    paddingBottom: 20,
    display: 'grid',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#fafbff',
    },
  },
  timestamps: {
    paddingTop: 8,
  },
}));

export interface IEmbeddingSummary {
  _id: string;
  name: string;
  dataset: string;
}

export interface IEmbedding {
  _id: string;
  embeddingSummary: string;
  sample: string;
  value: number[];
}

export interface ISamplingSummary {
  _id: string;
  name: string;
  dataset: string;
  method: string;
  tag: string;
  embedding: string;
}

export interface ISampling {
  _id: string;
  samplingSummary: string;
  sample: string;
  score: number;
}

interface IDatasetSamplingProps {
  summary: ISummary;
  embeddingSummaries: IEmbeddingSummary[];
  tag: ITag;
  datasetId: string;
  onDatasetSamplingsNeedReload: () => void;
  onDatasetEmbeddingsNeedReload: () => void;
}

const DatasetSampling: FunctionComponent<IDatasetSamplingProps> = (
  props: IDatasetSamplingProps,
) => {
  const classes = useStyles();

  const {
    summary,
    embeddingSummaries,
    tag,
    datasetId,
    onDatasetSamplingsNeedReload,
    onDatasetEmbeddingsNeedReload,
  } = props;

  const [samplingName, setSamplingName] = useState('');
  const [samplingNameError, setSamplingNameError] = useState(false);
  const [samplingNameHelperText, setSamplingNameHelperText] = useState('');
  const [processStarted, setProcessStarted] = useState(false);

  const [currentSamplingStrategy, setCurrentSamplingStrategy] = useState('bit');
  const [currentEmbedding, setCurrentEmbedding] = useState('default');
  const [embeddingList, setEmbeddingList] = useState<any>([]);

  const { getTokenSilently } = useAuth0();

  useEffect(() => {
    if (embeddingSummaries.length > 0) {
      setCurrentEmbedding(embeddingSummaries[0].name);
      setEmbeddingList(embeddingSummaries.map((val) => {
        return <MenuItem key={val.name} value={val.name}>{val.name}</MenuItem>;
      }));
    }
  }, [embeddingSummaries]);

  useEffect(() => {
    onDatasetSamplingsNeedReload();
    onDatasetEmbeddingsNeedReload();
  }, []);

  const handleProcessButtonClick = async () => {
    if (samplingName.length >= MIN_NAME_CHARACTERS && !nameRegExpFormat.test(samplingName)) {
      setSamplingNameError(false);
      setProcessStarted(true);
      const embeddingId = embeddingSummaries.filter(elem => elem.name === currentEmbedding)[0]._id;
      const auth0token = await getTokenSilently();
      axios
        .request({
          method: 'post',
          url: `${SAMPLING_SERVER_LOCATION}/sample`,
          headers: {
            Authorization: `Bearer ${auth0token}`,
          },
          data: {
            datasetId,
            samplingMethod: currentSamplingStrategy,
            embeddingId,
            tagId: tag._id,
            samplingName,
          },
        })
        .then(() => {
          setSamplingName('');
          setProcessStarted(false);
          onDatasetSamplingsNeedReload();
        });
    } else {
      setSamplingNameError(true);
      if (containsSpaceRegExpFormat.test(samplingName)) {
        setSamplingNameHelperText('Whitespaces are not allowed in sampling name!');
      } else if (nameRegExpFormat.test(samplingName)) {
        setSamplingNameHelperText('Sampling name must be at least 3 characters');
      } else {
        setSamplingNameHelperText('No special characters except - and _ are allowed');
      }
    }
  };

  const datasetNameChange = (event: any) => {
    setSamplingName(event.target.value);
  };

  const handleCurrentEmbeddingSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCurrentEmbedding(event.target.value as string);
  };

  return (
    <div>
      { summary.name && (
        <Grid
          container
          alignContent="flex-start"
          direction="column"
          justify="flex-start"
          className={clsx(classes.spacing)}
        >
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" href="/datasets">
                My Datasets
              </Link>
              <Link color="inherit" href={`/datasets/${summary._id}`}>
                {summary.name}
              </Link>
              <Typography color="textPrimary">Sampling</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              alignContent="center"
              direction="column"
              justify="center"
            >
              { embeddingSummaries.length > 0 ? (
                <Grid item>
                  <h3>Request new Sampling</h3>
                  { !processStarted ? (
                    <div>
                      <Grid item>
                        <h4>1. Select embedding which you want to use for sampling</h4>
                        <FormControl className={clsx(classes.formControl)}>
                          <InputLabel id="select-embedding-label">Embedding</InputLabel>
                          <Select
                            labelId="select-embedding-label"
                            value={currentEmbedding}
                            onChange={handleCurrentEmbeddingSelectChange}
                          >
                            {embeddingList}
                          </Select>
                        </FormControl>
                        <h4>2. Select the sampling strategy you want to use</h4>
                        <FormControl className={clsx(classes.formControl)}>
                          <InputLabel id="select-sampling-label">Sampling Strategy</InputLabel>
                          <Select
                            labelId="select-sampling-label"
                            value={currentSamplingStrategy}
                            onChange={(e: any) => setCurrentSamplingStrategy(e.target.value)}
                          >
                            <MenuItem key="random" value="random">Random</MenuItem>
                            <MenuItem key="bit" value="bit">Bit</MenuItem>
                            <MenuItem key="coreset" value="coreset">Coreset</MenuItem>
                          </Select>
                        </FormControl>
                        <h4>3. Give your sampling a name</h4>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="name"
                          label="Name"
                          type="text"
                          value={samplingName}
                          onChange={datasetNameChange}
                          error={samplingNameError}
                          helperText={samplingNameHelperText}
                        />
                      </Grid>
                      <Button
                        className={clsx(classes.spacing)}
                        variant="outlined"
                        color="primary"
                        onClick={handleProcessButtonClick}
                      >
                        Process
                      </Button>
                    </div>
                  ) : (
                    <Grid item>
                      <p>Your sampling job is now being processed!</p>
                      <CircularProgress />
                    </Grid>
                  )}
                </Grid>
              ) : (
                <Grid item>
                  <p>You need to upload embeddings before you can use sampling methods!</p>
                </Grid>
              )}

            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default DatasetSampling;
