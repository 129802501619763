/* eslint-disable import/prefer-default-export */

import React, { FunctionComponent } from 'react'; // importing FunctionComponent
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  copyright: {},
  liststyle: {
    listStyleType: 'decimal',
  },
  howitworks: {
    backgroundColor: 'white',
  },
  plots: {
    width: '100%',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));

export const HowItWorks: FunctionComponent = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      alignContent="stretch"
      direction="row"
      justify="center"
      className={clsx(classes.howitworks)}
    >
      <Grid item xs={10} md={8} className={clsx(classes.copyright)}>
        <h2>What is WhatToLabel?</h2>
        <p>
          WhatToLabel helps you improving your AI by filtering raw datasets. Our
          algorithms analyze your dataset and remove less informative samples.
          This helps you save annotation costs and improves the generalization
          and accuracy of your model.
        </p>

        <h2>How it Works</h2>
        <h4>How do I use WhatToLabel?</h4>
        <ul className={clsx(classes.liststyle)}>
          <li>Turn your local dataset into a supported archive format</li>
          <p>
            In order for our system to automatically process your dataset we
            need it in a certain format. We assume, that your data is stored
            within a folder. Your samples can either be in subfolders or
            directly in the root folder. Before uploading the dataset you will
            need to convert it into an supported archive format.
          </p>
          <li>Upload and submit your dataset for processing</li>
          <p>
            In this step you fill out our dataset submission form. You can
            select a desired filter strength. If you tell us what you will use
            the dataset for we can do additional optimizations.
          </p>
          <p>Upload your dataset in one of the supported archive formats.</p>
          <li>
            We will process your dataset and send you the report to your entered
            mail address
          </li>
          <p>
            You will receive a mail from us with a report and instructions to
            access your filtered dataset.
          </p>
        </ul>
        <h4>What archive formats are supported?</h4>
        <p>We currently support the following formats: .tar, .zip</p>
        <h4>What kind of samples/ data can you filter?</h4>
        <p>
          Our current system supports filtering of the following dataformats:
        </p>
        <ul>
          <li>For image data: BMP, GIF, JPEG, JPEG 2000, PNG, TIFF, WebP</li>
          <li>
            We are working on supporting other data types such as text and audio
            for our next release.
          </li>
        </ul>
        <h4>What happens with my dataset?</h4>
        <p>
          We respect privacy of your data. The data will be processed
          automatically by our system. Our servers will process the dataset as
          soon as possible. Once finished, the uploaded dataset will be deleted
          from our servers.
        </p>
        <h4>Why should I use WhatToLabel?</h4>
        <p>
          We optimized our toolchain to speed up computation and reduce memory
          usage and make sure we can provide the best user experience. So you
          can focus on training a machine learning model without having to worry
          about the dataset quality.
        </p>
        <p>
          In the following plots you see how WhatToLabel outperforms random
          subsampling on well-known acadedmic datasets.
        </p>
        <Grid container alignContent="stretch" direction="row" justify="center">
          <Grid item md>
            <img
              className={clsx(classes.plots)}
              alt="cifar10 evaluation plot"
              src="cifar10_eval_wtl_rnd.png"
            />
          </Grid>
          <Grid item md>
            <img
              className={clsx(classes.plots)}
              alt="cityscapes evaluation plot"
              src="cityscapes_eval_wtl_rnd.png"
            />
          </Grid>
          <Grid item md>
            <img
              className={clsx(classes.plots)}
              alt="camvid evaluation plot"
              src="camvid_eval_wtl_rnd.png"
            />
          </Grid>
        </Grid>
        <h4>Any further Questions?</h4>
        <p>
          Contact us via mail
          {' '}
          <a href="mailto:support@whattolabel.com">support@whattolabel.com</a>
        </p>
      </Grid>
    </Grid>
  );
};
