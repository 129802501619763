import React, { FunctionComponent } from 'react';
import {
  Switch,
  Route,
  RouteComponentProps,
  RouteProps,
  Redirect,
} from 'react-router-dom';
import Navbar from './Navbar';
import Datasets from './Datasets';
import DatasetDetail from './mydatasets/DatasetDetail';
import Filter from './Filter';
import Home from './Home';
import Preferences from './Preferences';
import { useAuth0 } from '../contexts/auth0-context';
import '../css/App.css';


export interface ProtectedRouteProps extends RouteProps {
  isAuthenticated?: boolean;
  isAllowed?: boolean;
  restrictedPath: string;
  authenticationPath: string;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = (props: ProtectedRouteProps) => {
  const {
    isAuthenticated, isAllowed, authenticationPath, restrictedPath,
  } = props;

  let redirectPath = '';
  if (!isAuthenticated) {
    redirectPath = authenticationPath;
    console.log(`not authenticated ${redirectPath}`);
  }
  if (isAuthenticated && !isAllowed) {
    redirectPath = restrictedPath;
  }

  if (redirectPath) {
    const renderComponent = () => <Redirect to={{ pathname: redirectPath }} />;
    return <Route {...props} component={renderComponent} render={undefined} />;
  }
  return <Route {...props} />;
};


const App: FunctionComponent = () => {
  const { isAuthenticated } = useAuth0();

  return (
    <div>
      <Navbar />
      <Switch>
        <Route path="/" exact>
          <Home />
          {/* <Filter /> */}
          {/* <Redirect to="/datasets" /> */}
        </Route>
        <Route path="/preferences" exact>
          <Preferences />
        </Route>
        <Route path="/datasets" exact>
          <Datasets />
        </Route>
        {/* <ProtectedRoute
          path="/datasets"
          isAuthenticated={isAuthenticated}
          isAllowed
          component={Datasets}
          restrictedPath="/"
          authenticationPath="/login"
          exact
        /> */}
        <Route path="/datasets/:datasetId">
          <DatasetDetail />
        </Route>
        <Route path="/filter" exact>
          <Filter />
        </Route>
      </Switch>
    </div>
  );
};

export default App;
