import React, { FunctionComponent } from 'react';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import { displayBytes } from '../../utils';

const useStyles = makeStyles(() => ({
  copyright: {
    textAlign: 'right',
    padding: '0.5em',
  },
  version: {
    textAlign: 'left',
    padding: '0.5em',
  },
  spacing: {
    marginTop: 10,
  },
  text: {
    color: '#FFFFFF',
  },
  dataset: {
    padding: 10,
    paddingBottom: 20,
    display: 'grid',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#fafbff',
    },
  },
  timestamps: {
    paddingTop: 8,
  },
  deleteNameIcon: {
    fontSize: '1.2em',
    cursor: 'pointer',
  },
}));

export interface ISummary {
  _id?: string,
  name: string,
  type?: string,
  nSamples?: number,
  sizeInBytes?: number,
  createdAt?: number,
  lastModifiedAt?: number,
  onDatasetDelete?: (dataset: string) => void,
}

const DatasetSingle: FunctionComponent<ISummary> = (props: ISummary) => {
  const classes = useStyles();

  const {
    _id, name, type, nSamples, sizeInBytes, createdAt, onDatasetDelete,
  } = props;


  const history = useHistory();

  const detailUrl = `/datasets/${_id}`;

  const handleClickOpen = () => {
    history.push(detailUrl);
  };

  const handleDeleteDataset = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    if (onDatasetDelete !== undefined) {
      onDatasetDelete(_id as string);
    }
  };

  return (
    <Paper elevation={3} className={clsx(classes.dataset)} onClick={handleClickOpen}>
      <Grid
        container
        alignContent="center"
        direction="row"
        justify="center"
      >
        <Grid
          item
          xs={10}
        >
          <Grid
            container
            alignContent="center"
            direction="column"
            justify="center"
          >
            <Grid container direction="row" justify="space-between" alignItems="center">
              <Grid item><h3>{name}</h3></Grid>
              <Grid item>
                <h3>
                  <DeleteIcon
                    className={clsx(classes.deleteNameIcon)}
                    onClick={handleDeleteDataset}
                  />
                </h3>
              </Grid>
            </Grid>
            <Grid container direction="row" justify="space-between">
              <Grid item>Type</Grid>
              <Grid item>{type}</Grid>
            </Grid>
            <Grid container direction="row" justify="space-between">
              <Grid item>#samples</Grid>
              <Grid item>{nSamples}</Grid>
            </Grid>
            <Grid container direction="row" justify="space-between">
              <Grid item>Size</Grid>
              <Grid item>
                {displayBytes(sizeInBytes)}
              </Grid>
            </Grid>
            <Grid container direction="row" justify="space-between" className={clsx(classes.timestamps)}>
              <Grid item><small>Created at</small></Grid>
              <Grid item><small>{moment((createdAt as any)).format('D.M.YYYY h:mm:ss a')}</small></Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default DatasetSingle;
