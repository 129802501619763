/* eslint-disable no-underscore-dangle */
import React, { FunctionComponent, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import {
  Switch,
  Route,
  withRouter,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import axios, { AxiosResponse } from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useAuth0 } from '../../contexts/auth0-context';
import { ISummary } from './DatasetSingle';
import DatasetMenu, { ITag } from './DatasetMenu';
import DatasetHome from './DatasetHome';
import DatasetMetadata from './DatasetMetadata';
import DatasetHistogram from './DatasetHistogram';
import DatasetSampling, { IEmbeddingSummary, ISamplingSummary } from './DatasetSampling';
import SamplingProcess from './SamplingProcess';
import DatasetDownload from './DatasetDownload';
import {
  SERVER_LOCATION,
} from '../../constants';

const useStyles = makeStyles(() => ({
  spacing: {
    marginTop: 10,
  },
  menu: {
    paddingRight: '25px',
    paddingLeft: '10px',
  },
}));

export interface ISamples {
  data: {
    dstUrl: string,
    fileName: string,
  },
  meta?: {
    mean: number[],
    custom?: Object,
    shape: number[],
    sizeInBytes: number,
    snr: number,
    std: number[],
    sumOfSquares: number[],
    sumOfValues: number[];
    sharpness: number,
  }
  _id: string,
}

const DatasetDetail: FunctionComponent = () => {
  const classes = useStyles();

  const { datasetId } = useParams();
  const match = useRouteMatch();

  const [datasetSummary, setDatasetSummary] = useState<ISummary>({ name: '' });
  const [datasetAllSamples, setDatasetAllSamples] = useState<ISamples[]>([]);
  const [datasetCurrentSamples, setDatasetCurrentSamples] = useState<ISamples[]>([]);
  const [datasetTags, setDatasetTags] = useState<ITag[]>([{ _id: '', name: '' }]);
  const [datasetEmbeddingSummaries, setDatasetEmbeddingSummaries] = useState<
    IEmbeddingSummary[]
  >([]);
  const [datasetSamplingSummaries, setDatasetSamplingSummaries] = useState<ISamplingSummary[]>([]);
  const [currentTag, setCurrentTag] = useState<ITag>({ _id: '', name: '' });
  const [datasetSummaryNeedsReload, setDatasetSummaryNeedsReload] = useState(true);
  const [datasetSamplesNeedsReload, setDatasetSamplesNeedsReload] = useState(true);
  const [datasetTagsNeedsReload, setDatasetTagsNeedsReload] = useState(true);
  const [
    datasetEmbeddingSummariesNeedReload,
    setDatasetEmbeddingSummariesNeedReload,
  ] = useState(false);
  const [datasetSamplingsNeedReload, setDatasetSamplingsNeedReload] = useState(false);
  const [currentSampleSelection, setCurrentSampleSelection] = useState<string[]>([]);

  const [userToken, setUserToken] = useState<string>('');

  const { isLoading, getTokenSilently } = useAuth0();

  // load dataset summary
  useEffect(() => {
    async function fetchData() {
      const auth0token = await getTokenSilently();
      axios
        .get(`${SERVER_LOCATION}/users/datasets/${datasetId}`, {
          headers: {
            Authorization: `Bearer ${auth0token}`,
          },
        })
        .then((res: AxiosResponse) => {
          setDatasetSummary(res.data);
          setDatasetSummaryNeedsReload(false);
        });
    }
    if (!isLoading && datasetSummaryNeedsReload) {
      fetchData();
    }
  }, [isLoading, datasetSummaryNeedsReload, getTokenSilently, datasetId]);

  // load user token
  useEffect(() => {
    async function fetchData() {
      const auth0token = await getTokenSilently();
      axios
        .get(`${SERVER_LOCATION}/users/tokens`, {
          headers: {
            Authorization: `Bearer ${auth0token}`,
          },
        })
        .then((res: AxiosResponse) => {
          setUserToken(res.data);
        });
    }
    if (!isLoading && userToken === '') {
      fetchData();
    }
  }, [isLoading]);

  // load dataset embeddings
  useEffect(() => {
    async function fetchEmbeddings() {
      const auth0token = await getTokenSilently();

      axios
        .get(`${SERVER_LOCATION}/users/datasets/${datasetId}/embeddings`, {
          headers: {
            Authorization: `Bearer ${auth0token}`,
          },
        })
        .then((res: AxiosResponse) => {
          if (res.data.length === 0 || res.data.length === undefined) {
            setDatasetEmbeddingSummaries([]);
          } else {
            setDatasetEmbeddingSummaries(res.data);
          }
        });
    }
    if (!isLoading && datasetEmbeddingSummariesNeedReload) {
      fetchEmbeddings();
      setDatasetEmbeddingSummariesNeedReload(false);
    }
  }, [isLoading, datasetEmbeddingSummariesNeedReload, getTokenSilently, datasetId]);

  // load dataset samplings
  useEffect(() => {
    async function fetchSamplings() {
      const auth0token = await getTokenSilently();
      axios
        .get(`${SERVER_LOCATION}/users/datasets/${datasetId}/samplings`, {
          headers: {
            Authorization: `Bearer ${auth0token}`,
          },
        })
        .then((res: AxiosResponse) => {
          if (res.data.length === 0 || res.data.length === undefined) {
            setDatasetSamplingSummaries([]);
          } else {
            setDatasetSamplingSummaries(res.data);
          }
        });
    }
    if (!isLoading && datasetSamplingsNeedReload) {
      fetchSamplings();
      setDatasetSamplingsNeedReload(false);
    }
  }, [isLoading, datasetSamplingsNeedReload, getTokenSilently, datasetId]);

  // load dataset tags
  useEffect(() => {
    async function fetchCurrentTags() {
      const auth0token = await getTokenSilently();
      axios
        .get(`${SERVER_LOCATION}/users/datasets/${datasetId}/tags`, {
          headers: {
            Authorization: `Bearer ${auth0token}`,
          },
        })
        .then((res: AxiosResponse) => {
          if (res.data.length === 0 || res.data.length === undefined) {
            setDatasetTags([{ _id: '', name: '__undefined__' }]);
            setCurrentTag({ _id: '', name: '__undefined__' });
          } else {
            setDatasetTags(res.data);
            setCurrentTag(res.data[0]);
          }
        });
    }
    if (!isLoading && datasetTagsNeedsReload) {
      fetchCurrentTags();
      setDatasetTagsNeedsReload(false);
    }
  }, [isLoading, datasetTagsNeedsReload, getTokenSilently, datasetId]);

  // load dataset samples
  useEffect(() => {
    async function fetchDatasetSamples() {
      const auth0token = await getTokenSilently();
      axios
        .get(`${SERVER_LOCATION}/users/datasets/${datasetId}/samples?mode=full`, {
          headers: {
            Authorization: `Bearer ${auth0token}`,
          },
        })
        .then((res: AxiosResponse) => {
          if (res.data.length >= 0) {
            setDatasetAllSamples(res.data);
            setDatasetSamplesNeedsReload(false);
          }
        });
    }
    if (!isLoading && datasetSamplesNeedsReload) {
      fetchDatasetSamples();
    }
  }, [isLoading, datasetSamplesNeedsReload, getTokenSilently, datasetId]);

  // update current samples to work with
  useEffect(() => {
    if (datasetTags.length > 0 && datasetTags[0].name !== '' && datasetAllSamples.length > 0) {
      const newSamples = datasetAllSamples.filter((sample) => {
        const tag = currentTag as ITag;
        if (tag.name === '__undefined__' || !tag.name) {
          return true;
        }
        return tag.listOfSampleIds!.includes(sample._id as string);
      });
      setDatasetCurrentSamples(newSamples);
      const listOfSampleRefs = newSamples.map((d: ISamples) => d._id as string);
      setCurrentSampleSelection(listOfSampleRefs);
    }
  }, [datasetAllSamples, datasetTags, currentTag]);

  const handleDatasetSummaryReloadChange = () => {
    setDatasetSummaryNeedsReload(true);
  };

  const handleDatasetSamplesReloadChange = () => {
    setDatasetSamplesNeedsReload(true);
  };

  const handleDatasetSamplingsReloadChange = () => {
    setDatasetSamplingsNeedReload(true);
  };

  const handleDatasetEmbeddingsReloadChange = () => {
    setDatasetEmbeddingSummariesNeedReload(true);
  };

  const handleListOfActiveSamplesChange = (data: string[]) => {
    setCurrentSampleSelection(data);
  };

  return (
    <div>
      <Grid
        container
        alignContent="stretch"
        direction="row"
        justify="flex-start"
        className={clsx(classes.spacing)}
      >
        <Grid item sm={5} md={4} lg={3} xl={2} className={clsx(classes.menu)}>
          <DatasetMenu
            tags={datasetTags}
            summary={datasetSummary}
            onDatasetTagsNeedsReload={() => setDatasetTagsNeedsReload(true)}
            onCurrentTagChange={d => setCurrentTag(d)}
            listOfActiveSamples={currentSampleSelection}
            samples={datasetAllSamples}
          />
        </Grid>
        <Grid item sm={7} md={8} lg={9} xl={10}>
          <Switch>
            <Route path={`${match.path}`} exact>
              <DatasetHome
                onDatasetSummaryNeedReloadChange={handleDatasetSummaryReloadChange}
                onDatasetSamplesNeedReloadChange={handleDatasetSamplesReloadChange}
                summary={datasetSummary}
                userToken={userToken}
                datasetSummaryNeedsReload={datasetSummaryNeedsReload}
                onDatasetTagsNeedsReload={() => setDatasetTagsNeedsReload(true)}
              />
            </Route>
            <Route path={`${match.path}/metadata`} exact>
              <DatasetMetadata
                onHandleDatasetSummaryReloadChange={handleDatasetSummaryReloadChange}
                summary={datasetSummary}
              />
            </Route>
            <Route path={`${match.path}/histogram`} exact>
              <DatasetHistogram
                samples={datasetCurrentSamples}
                onListOfActiveSamplesChange={handleListOfActiveSamplesChange}
                onDatasetSamplesNeedReloadChange={handleDatasetSamplesReloadChange}
                tag={currentTag}
                listOfActiveSamples={currentSampleSelection}
                summary={datasetSummary}
              />
            </Route>
            <Route path={`${match.path}/sampling`} exact>
              <Grid container>
                <Grid item xs={6}>
                  <DatasetSampling
                    summary={datasetSummary}
                    embeddingSummaries={datasetEmbeddingSummaries}
                    tag={currentTag}
                    datasetId={datasetId}
                    onDatasetSamplingsNeedReload={handleDatasetSamplingsReloadChange}
                    onDatasetEmbeddingsNeedReload={handleDatasetEmbeddingsReloadChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SamplingProcess
                    tag={currentTag}
                    samplingSummaries={datasetSamplingSummaries}
                    listOfActiveSamples={currentSampleSelection}
                    onListOfActiveSamplesChange={handleListOfActiveSamplesChange}
                  />
                </Grid>
              </Grid>
            </Route>
            <Route path={`${match.path}/download`} exact>
              <DatasetDownload
                tag={currentTag}
                samples={datasetCurrentSamples}
                listOfActiveSamples={currentSampleSelection}
                datasetId={datasetId}
                summary={datasetSummary}
              />
            </Route>
          </Switch>
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(DatasetDetail);
