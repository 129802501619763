/* eslint-disable no-underscore-dangle */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import axios, { AxiosResponse } from 'axios';
import { useAuth0 } from '../../contexts/auth0-context';
import Histogram from './Histogram';
import DecisionPreview from './DecisionPreview';
import { ISamples } from './DatasetDetail';
import { ITag } from './DatasetMenu';
import { ISummary } from './DatasetSingle';
import {
  SERVER_LOCATION,
} from '../../constants';

const useStyles = makeStyles(() => ({
  copyright: {
    textAlign: 'right',
    padding: '0.5em',
  },
  version: {
    textAlign: 'left',
    padding: '0.5em',
  },
  spacing: {
    marginTop: 10,
  },
  svgfix: {
    width: 'fit-content',
  },
  text: {
    color: '#FFFFFF',
  },
  preview: {
    padding: 5,
    marginBottom: 15,
  },
}));

function isInRange(val: number, range: number[]): boolean {
  if (val >= range[0] && val <= range[1]) {
    return true;
  }
  return false;
}

interface IDatasetHistogramProps {
  samples: ISamples[],
  listOfActiveSamples: string[],
  onListOfActiveSamplesChange: (ev: string[]) => void,
  onDatasetSamplesNeedReloadChange: () => void,
  summary: ISummary,
  tag: ITag,
}

const DatasetHistogram: FunctionComponent<IDatasetHistogramProps> = (
  props: IDatasetHistogramProps,
) => {
  const classes = useStyles();

  const [customDataName, setCustomDataName] = useState('');

  const [histSnrData, setHistSnrData] = useState([] as number[]);
  const [histSizeData, setHistSizeData] = useState([] as number[]);
  const [histImgWidthData, setHistImgWidthData] = useState([] as number[]);
  const [histImgHeightData, setHistImgHeightData] = useState([] as number[]);
  const [histAspectRatioData, setHistAspectRatioData] = useState([] as number[]);
  const [histSharpnessData, setHistSharpnessData] = useState([] as number[]);
  const [histCustomData, setHistCustomData] = useState([] as number[]);

  const [histSnrSliderVal, setHistSnrSliderVal] = useState([0, 1]);
  const [histSizeSliderVal, setHistSizeSliderVal] = useState([0, 1]);
  const [histImgWidthSliderVal, setHistImgWidthSliderVal] = useState([0, 1]);
  const [histImgHeightSliderVal, setHistImgHeightSliderVal] = useState([0, 1]);
  const [histAspectRatioSliderVal, setHistAspectRatioSliderVal] = useState([0, 1]);
  const [histSharpnessSliderVal, setHistSharpnessSliderVal] = useState([0, 1]);
  const [histCustomSliderVal, setHistCustomSliderVal] = useState([0, 1]);

  const [imgLeftData, setImgLeftData] = useState('');
  const [imgRightData, setImgRightData] = useState('');

  const [decisionName, setDecisionName] = useState('');
  const [decisionValue, setDecisionValue] = useState(0);

  const [urlSrcDict, setUrlSrcDict] = useState<Object[]>({} as any);

  const { isLoading, getTokenSilently } = useAuth0();

  const {
    samples, tag, onListOfActiveSamplesChange, summary, onDatasetSamplesNeedReloadChange,
  } = props;

  // Update states when new data has been fetched from server
  useEffect(() => {
    if (samples.length > 0) {
      const newHistSnrData = samples.map(
        sample => sample.meta?.snr as number,
      );
      setHistSnrData(newHistSnrData);

      const newHistSizeData = samples.map(
        sample => sample.meta?.sizeInBytes as number,
      );
      setHistSizeData(newHistSizeData);

      const newHistImgWidthData = samples.map(
        sample => sample.meta?.shape[1] as number,
      );
      setHistImgWidthData(newHistImgWidthData);

      const newHistImgHeightData = samples.map(
        sample => sample.meta?.shape[0] as number,
      );
      setHistImgHeightData(newHistImgHeightData);

      const newHistAspectRatioData = samples.map(
        (sample) => {
          const h = sample.meta?.shape[0] as number;
          const w = sample.meta?.shape[1] as number;
          return w / h;
        },
      );
      setHistAspectRatioData(newHistAspectRatioData);

      const newHistSharpnessData = samples.map(
        sample => sample.meta?.sharpness as number,
      );
      setHistSharpnessData(newHistSharpnessData);

      if (samples[0].meta?.custom) {
        const customDataKey = samples.length > 0 ? Object.keys(samples[0].meta?.custom as Object)[0] : '';
        const newHistCustomData = samples.map(
          sample => (sample.meta?.custom as any)[customDataKey] as number,
        );
        setHistCustomData(newHistCustomData);
        setCustomDataName(customDataKey);
      }
    }
  }, [samples]);

  // Update states when user moved sliders
  useEffect(() => {
    async function fetchDataAndUpdateState(
      toUpdate: React.Dispatch<React.SetStateAction<string>>,
      imgRef: string,
    ) {
      const hashIdx = `${imgRef}`;
      if (hashIdx in urlSrcDict) {
        const imgVal = urlSrcDict[hashIdx as any] as string;
        toUpdate(imgVal);
      } else {
        const auth0token = await getTokenSilently();
        axios
          .get(`${SERVER_LOCATION}/users/datasets/${summary._id}/samples/${imgRef}/readurl`, {
            headers: {
              Authorization: `Bearer ${auth0token}`,
            },
          })
          .then((res: AxiosResponse) => {
            if (res.data) {
              axios.get(
                res.data,
                {
                  responseType: 'arraybuffer',
                },
              )
                .then((resImg: AxiosResponse) => {
                  const base64 = btoa(
                    new Uint8Array(resImg.data).reduce(
                      (data, byte) => data + String.fromCharCode(byte),
                      '',
                    ),
                  );
                  const imgValue = `data:${resImg.headers['content-type'].toLowerCase()};base64,${base64}`;
                  const newSrcDict = urlSrcDict;
                  newSrcDict[hashIdx as any] = imgValue as any;
                  setUrlSrcDict(newSrcDict);
                  toUpdate(imgValue);
                });
            }
          });
      }
    }
    if (!isLoading && samples.length > 0) {
      // TODO: Make all hardcoded histograms dynamic
      // Find elements around decision boundary
      if (decisionName !== '') {
        let leftSampleIdx = -1;
        let rightSampleIdx = -1;
        const relevantValues = samples.map((sample) => {
          const imgWidthVal = sample.meta?.shape[1] as number;
          const imgHeightVal = sample.meta?.shape[0] as number;
          switch (decisionName) {
            case 'snr':
              return sample.meta?.snr as number;
            case 'img width':
              return sample.meta?.shape[1] as number;
            case 'img height':
              return sample.meta?.shape[0] as number;
            case 'img aspect ratio':
              return imgWidthVal / imgHeightVal;
            case 'img size':
              return sample.meta?.sizeInBytes as number;
            case 'sharpness':
              return sample.meta?.sharpness as number;
            default:
              return sample.meta?.snr as number;
          }
        });
        relevantValues.forEach((val, idx) => {
          if (val && val < decisionValue) {
            if (leftSampleIdx >= 0 && (relevantValues[leftSampleIdx] as number) < val) {
              leftSampleIdx = idx;
            } else if (leftSampleIdx === -1) {
              leftSampleIdx = idx;
            }
          }
          if (val && val > decisionValue) {
            if (rightSampleIdx >= 0 && (relevantValues[rightSampleIdx] as number) > val) {
              rightSampleIdx = idx;
            } else if (rightSampleIdx === -1) {
              rightSampleIdx = idx;
            }
          }
        });
        if (leftSampleIdx >= 0) {
          const imgRefLeft = samples[leftSampleIdx]._id as string;
          fetchDataAndUpdateState(setImgLeftData, imgRefLeft);
        }
        if (rightSampleIdx >= 0) {
          const imgRefRight = samples[rightSampleIdx]._id as string;
          fetchDataAndUpdateState(setImgRightData, imgRefRight);
        }
      }

      // update samples
      let listOfFiles = tag.listOfSampleIds as string[];
      if (listOfFiles === undefined) {
        listOfFiles = samples.map(d => d._id as string);
      }

      const listOfFilesSet = new Set(listOfFiles);
      const filteredSamples = samples.filter(sample => listOfFilesSet.has(sample._id as string));

      const newActiveSamples = filteredSamples.filter((sample) => {
        const snrVal = sample.meta?.snr as number;
        const sizeVal = sample.meta?.sizeInBytes as number;
        const imgWidthVal = sample.meta?.shape[1] as number;
        const imgHeightVal = sample.meta?.shape[0] as number;
        const imgAspectRatioVal = imgWidthVal / imgHeightVal;
        const imgSharpnessVal = sample.meta?.sharpness as number;

        let customValCheck = true;
        if (samples[0].meta?.custom) {
          const customDataKey = samples.length > 0 ? Object.keys(samples[0].meta?.custom as Object)[0] : '';
          const customVal = (sample.meta?.custom as any)[customDataKey] as number;
          customValCheck = isInRange(customVal, histCustomSliderVal);
        }
        const snrCheck = isInRange(snrVal, histSnrSliderVal);
        const sizeCheck = isInRange(sizeVal, histSizeSliderVal);
        const imgWidthCheck = isInRange(imgWidthVal, histImgWidthSliderVal);
        const imgHeightCheck = isInRange(imgHeightVal, histImgHeightSliderVal);
        const imgAspectRatioCheck = isInRange(imgAspectRatioVal, histAspectRatioSliderVal);
        const imgSharpnessCheck = isInRange(imgSharpnessVal, histSharpnessSliderVal);
        if (snrCheck && sizeCheck && imgWidthCheck && imgHeightCheck
          && imgAspectRatioCheck && imgSharpnessCheck && customValCheck) {
          return true;
        }
        return false;
      });

      const newActiveSamplesList = newActiveSamples.map(
        d => d._id as string,
      );
      onListOfActiveSamplesChange(newActiveSamplesList);
    }
  }, [
    histSnrSliderVal,
    histSizeSliderVal,
    histImgWidthSliderVal,
    histImgHeightSliderVal,
    histAspectRatioSliderVal,
    histSharpnessSliderVal,
    histCustomSliderVal,
    tag,
    isLoading,
  ]);

  /**
   * Function to track which slider has to be updated and track this in state
   * @param currentState Current slider value state
   * @param toUpdateState Slider state action to update slider val state
   * @param val New value of slider
   * @param name Name of slider which has triggered the event
   */
  const updateSliderState = (
    currentState: number[],
    toUpdateState: React.Dispatch<React.SetStateAction<number[]>>,
    val: number[],
    name: string,
  ) => {
    if (val[1] !== currentState[1]) {
      setDecisionName(name);
      setDecisionValue(val[1]);
    } else if (val[0] !== currentState[0]) {
      setDecisionName(name);
      setDecisionValue(val[0]);
    }
    toUpdateState(val);
  };

  return (
    <div>
      { tag.name !== '__undefined__' && samples.length > 0 && samples[0].meta ? (
        <Grid
          container
          alignContent="stretch"
          direction="row"
          justify="space-evenly"
          className={clsx(classes.spacing)}
        >
          <Grid item xs={12}>
            { summary.name && (
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" href="/datasets">
                  My Datasets
                </Link>
                <Link color="inherit" href={`/datasets/${summary._id}`}>
                  {summary.name}
                </Link>
                <Typography color="textPrimary">Histogram</Typography>
              </Breadcrumbs>
            )}
          </Grid>
          <Grid item xs={12} className={clsx(classes.preview)}>
            <Grid container justify="center">
              <Grid item xl={6} md={8}>
                <DecisionPreview
                  imgLeftData={imgLeftData}
                  imgRightData={imgRightData}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="column"
            alignContent="stretch"
            justify="flex-start"
            xs={3}
          >
            <Grid item className={clsx(classes.svgfix)}>
              <Histogram
                title="Signal to noise ratio"
                data={histSnrData}
                onSliderValChange={val => updateSliderState(histSnrSliderVal, setHistSnrSliderVal, val, 'snr')}
              />
            </Grid>
            <Grid item className={clsx(classes.svgfix)}>
              <Histogram
                title="Size of samples in Bytes"
                data={histSizeData}
                onSliderValChange={val => updateSliderState(histSizeSliderVal, setHistSizeSliderVal, val, 'img size')}
              />
            </Grid>
            <Grid item className={clsx(classes.svgfix)}>
              <Histogram
                title={`Custom Data: ${customDataName}`}
                data={histCustomData}
                onSliderValChange={val => setHistCustomSliderVal(val)}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="column"
            alignContent="stretch"
            justify="flex-start"
            xs={3}
          >
            <Grid item className={clsx(classes.svgfix)}>
              <Histogram
                title="Image Width"
                data={histImgWidthData}
                onSliderValChange={val => updateSliderState(histImgWidthSliderVal, setHistImgWidthSliderVal, val, 'img width')}
              />
            </Grid>
            <Grid item className={clsx(classes.svgfix)}>
              <Histogram
                title="Image Height"
                data={histImgHeightData}
                onSliderValChange={val => updateSliderState(histImgHeightSliderVal, setHistImgHeightSliderVal, val, 'img height')}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="column"
            alignContent="stretch"
            justify="flex-start"
            xs={3}
          >
            <Grid item className={clsx(classes.svgfix)}>
              <Histogram
                title="Aspect Ratio (w/h)"
                data={histAspectRatioData}
                onSliderValChange={val => updateSliderState(histAspectRatioSliderVal, setHistAspectRatioSliderVal, val, 'img aspect ratio')}
              />
            </Grid>
            <Grid item className={clsx(classes.svgfix)}>
              <Histogram
                title="Sharpness"
                data={histSharpnessData}
                onSliderValChange={val => updateSliderState(histSharpnessSliderVal, setHistSharpnessSliderVal, val, 'sharpness')}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          alignContent="stretch"
          direction="row"
          justify="space-evenly"
          className={clsx(classes.spacing)}
        >
          <p>
            No tag available to filter! Check whether your dataset has been
            {' '}
            uploaded properly and whether you created an initial tag.
          </p>
        </Grid>
      )}
    </div>
  );
};

export default DatasetHistogram;
