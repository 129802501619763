import React, { FunctionComponent } from 'react';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(() => ({
  copyright: {
    textAlign: 'right',
    padding: '0.5em',
  },
  version: {
    textAlign: 'left',
    padding: '0.5em',
  },
  spacing: {
    marginTop: 10,
  },
  img: {
    border: '1px #999 dashed',
  },
  previewBlock: {
    height: 100,
  },
  decisionPreviewPaper: {
    padding: 10,
    paddingBottom: 20,
    display: 'grid',
    minHeight: 160,
  },
  verticalSeparator: {
    height: '90%',
    border: '1px #555 solid',
  },
  text: {
    textAlign: 'center',
  }

}));

export interface IDecisionPreview {
  imgLeftData: string,
  imgRightData: string,
}

const DecisionPreview: FunctionComponent<IDecisionPreview> = (props: IDecisionPreview) => {
  const classes = useStyles();

  const {
    imgLeftData, imgRightData,
  } = props;

  return (
    <Paper elevation={3} className={clsx(classes.decisionPreviewPaper)}>
      <Grid
        container
        alignContent="center"
        direction="row"
        justify="center"
      >
        <Grid item xs={6}><h3 className={clsx(classes.text)}>Decision Boundary Preview</h3></Grid>
        {imgLeftData !== '' && imgRightData !== '' ? (
          <Grid
            container
            alignContent="center"
            direction="row"
            justify="center"
            spacing={1}
            className={clsx(classes.previewBlock)}
          >
            {/* <Grid item xs={12}>{decisionName}</Grid> */}
            <Grid item>
              <img
                className={clsx(classes.img)}
                alt="custom meta json example"
                src={imgLeftData}
              />
            </Grid>
            <Grid item>
              <div className={clsx(classes.verticalSeparator)} />
            </Grid>
            <Grid item>
              <img
                className={clsx(classes.img)}
                alt="custom meta json example"
                src={imgRightData}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <p className={clsx(classes.text)}>Change the min / max values of a histogram to update the decision boundary.</p>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default DecisionPreview;
