/* eslint-disable no-useless-escape */
/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';

const displayBytes = (bytes: number | any): string => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0 || bytes === undefined) {
    return 'n/a';
  }

  const idx = Math.floor(Math.log(bytes) / Math.log(1024));
  if (idx === 0) {
    return `${bytes} ${sizes[idx]}`;
  }
  return `${(bytes / (1024 ** idx)).toFixed(1)} ${sizes[idx]}`;
};

const displayJson = (data: string) => {
  let newString = '';
  newString = data.replace('{', '{\n');
  newString = newString.replace(':', ': ');
  newString = newString.replace(',', ',\n');
  newString = newString.replace('}', '\n}');

  return newString.split('\n').map((text, idx) => (
    <Fragment key={`${text}-${idx}`}>
      {text}
      <br />
    </Fragment>
  ));
};

const nameRegExpFormat = /[!-\,\.-\/:-@[-^`{-~\s]/;
const containsSpaceRegExpFormat = /[\s]/;
const nameWithSpacesRegExpFormat = /[!-\,\.-\/:-@[-^`{-~]/;

export {
  displayBytes,
  displayJson,
  nameRegExpFormat,
  containsSpaceRegExpFormat,
  nameWithSpacesRegExpFormat,
};
