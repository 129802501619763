import React, { FunctionComponent } from 'react'; // importing FunctionComponent
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import { Link, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth0 } from '../contexts/auth0-context';

const useStyles = makeStyles(() => ({
  copyright: {
    textAlign: 'right',
    padding: '0.5em',
  },
  version: {
    textAlign: 'left',
    padding: '0.5em',
  },
  spacing: {
    marginTop: 10,
  },
  text: {
    color: '#FFFFFF',
  },
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    color: '#A72264',
  },
  navbar: {
    backgroundColor: '#EBEBEB',
  },
}));

const Navbar: FunctionComponent = () => {
  const {
    isLoading, user, loginWithRedirect, logout,
  } = useAuth0();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles();

  const handleOpenMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAnchorEl(null);
  };

  const handleMenuItemPreferencesClick = () => {
    setAnchorEl(null);
  };

  return (
    <header className={classes.root}>
      <AppBar position="static" className={clsx(classes.navbar)}>
        <Toolbar>
          <Link className={clsx(classes.title)} to="/">
            <Typography variant="h6" className={clsx(classes.title)}>
              WhatToLabel
              {' '}
              {process.env.REACT_APP_BUILD_MODE}
            </Typography>
          </Link>
          {!isLoading && user && (
            <Button
              size="medium"
              variant="text"
              component="label"
            >
              <a
                href="https://www.notion.so/whattolabel/WhatToLabel-Documentation-28e645f5564a453e807d0a384a4e6ea7"
                rel="noopener noreferrer"
                target="_blank"
              >
                Docs & Tutorials
              </a>
            </Button>
          )}
          <Link className="nav-link" to="/datasets">
            {!isLoading && user && (
            <Button
              size="medium"
              variant="text"
              component="label"
            >
              My Datasets
              <input type="button" style={{ display: 'none' }} />
            </Button>
            )}
          </Link>
          <Link className="nav-link" to="/">
            {!isLoading && !user && (
            <Button
              size="medium"
              variant="text"
              component="label"
              onClick={loginWithRedirect}
            >
              Sign In
            </Button>
            )}
            {!isLoading && user && (
              <div>
                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleOpenMenuClick}>
                  {user.email}
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <Link className="nav-link" to="/preferences">
                    <MenuItem onClick={handleMenuItemPreferencesClick}>Preferences</MenuItem>
                  </Link>
                  <MenuItem onClick={logout}>Logout</MenuItem>
                </Menu>
              </div>
            )}
          </Link>
        </Toolbar>
      </AppBar>
    </header>
  );
};

export default withRouter(Navbar);
