import React, { FunctionComponent, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import 'react-dropzone-uploader/dist/styles.css';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import axios, { AxiosResponse } from 'axios';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '../../contexts/auth0-context';
import { nameRegExpFormat, containsSpaceRegExpFormat } from '../../utils';
import {
  SERVER_LOCATION,
  MIN_NAME_CHARACTERS,
} from '../../constants';

const useStyles = makeStyles(() => ({
  copyright: {
    textAlign: 'right',
    padding: '0.5em',
  },
  version: {
    textAlign: 'left',
    padding: '0.5em',
  },
  spacing: {
    marginTop: 10,
  },
  text: {
    color: '#FFFFFF',
  },
  dataset: {
    padding: 10,
    display: 'grid',
    cursor: 'pointer',
    minHeight: '150px',
    '&:hover': {
      backgroundColor: '#fafbff',
    },
  },
  dialogTextfield: {
    minHeight: 80,
  },
}));

const DatasetCreate: FunctionComponent = () => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [datasetName, setDatasetName] = useState<string>('');
  const [datasetNameError, setDatasetNameError] = useState(false);
  const [datasetNameHelperText, setDatasetNameHelperText] = useState('');

  const { getTokenSilently } = useAuth0();
  const history = useHistory();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const validDatasetName = (name: string) => {
    if (name.length >= MIN_NAME_CHARACTERS && !nameRegExpFormat.test(name)) {
      return true;
    }
    setDatasetNameError(true);
    if (name.length <= MIN_NAME_CHARACTERS) {
      setDatasetNameHelperText('Dataset name requires at least 3 characters!');
    } else if (containsSpaceRegExpFormat.test(name)) {
      setDatasetNameHelperText('Whitespaces are not allowed in dataset name!');
    } else {
      setDatasetNameHelperText('No special characters except `-` and `_` are allowed!');
    }
    return false;
  };

  const datasetNameChange = (event: any) => {
    const name = event.target.value as string;
    setDatasetName(event.target.value);
    if (validDatasetName(name)) {
      setDatasetNameError(false);
      setDatasetNameHelperText('');
    }
  };

  const handleCreateClick = async () => {
    // create dataset request
    const name = datasetName;
    if (validDatasetName(name)) {
      setDatasetNameError(false);
      const auth0token = await getTokenSilently();
      axios
        .request({
          method: 'post',
          url: `${SERVER_LOCATION}/users/datasets`,
          data: { dataset: { name } },
          headers: {
            Authorization: `Bearer ${auth0token}`,
          },
        })
        .then((res: AxiosResponse) => {
          setOpen(false);
          const detailUrl = `/datasets/${res.data}`;
          history.push(detailUrl);
        });
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      handleCreateClick();
    }
  };

  return (
    <div>
      <Paper elevation={3} className={clsx(classes.dataset)} onClick={handleClickOpen}>
        <Grid
          container
          alignContent="center"
          direction="column"
          justify="center"
          className={clsx(classes.spacing)}
        >
          Create a new Dataset
        </Grid>
      </Paper>
      <Dialog open={open} onClose={handleCancel} onKeyPress={handleKeyPress} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create new dataset</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter a name for your new dataset. Only alphanumeric characters,
            {' '}
            `-` and `_` are allowed. Whitespaces are not allowed!
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            value={datasetName}
            onChange={datasetNameChange}
            fullWidth
            error={datasetNameError}
            helperText={datasetNameHelperText}
            className={clsx(classes.dialogTextfield)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCreateClick} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DatasetCreate;
