/* eslint-disable no-underscore-dangle */
import React, { FunctionComponent } from 'react';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import axios, { AxiosResponse } from 'axios';
import { ITag } from './DatasetMenu';
import { useAuth0 } from '../../contexts/auth0-context';
import { ISummary } from './DatasetSingle';
import { ISamples } from './DatasetDetail';
import { SERVER_LOCATION } from '../../constants';
import { displayBytes } from '../../utils';

const useStyles = makeStyles(() => ({
  copyright: {
    textAlign: 'right',
    padding: '0.5em',
  },
  version: {
    textAlign: 'left',
    padding: '0.5em',
  },
  spacing: {
    marginTop: 10,
  },
  text: {
    color: '#FFFFFF',
  },
  code: {
    fontSize: '0.9em',
    fontFamily: 'monospace',
  },
  codeIndent: {
    fontSize: '1.0em',
    fontFamily: 'monospace',
    paddingLeft: 25,
  },
  subHeading: {
    paddingTop: 20,
  },
}));

interface IDatasetDownloadProps {
  tag: ITag;
  datasetId: string;
  summary: ISummary;
  samples: ISamples[];
  listOfActiveSamples: string[];
}

const DatasetDownload: FunctionComponent<IDatasetDownloadProps> = (
  props: IDatasetDownloadProps,
) => {
  const classes = useStyles();
  const {
    tag, datasetId, summary, samples, listOfActiveSamples,
  } = props;
  const { getTokenSilently } = useAuth0();

  const computeSizeInBytes = (
    _samples: ISamples[],
    _listOfActiveSamples: string[],
  ) => {
    const activeSamples = _samples.filter((val) => {
      const isActiveSample = _listOfActiveSamples.includes(val._id as string);
      return isActiveSample;
    });
    const activeSamplesSizesInBytes = activeSamples.map(
      val => val.meta?.sizeInBytes as number,
    );
    return activeSamplesSizesInBytes.reduce((a, b) => a + b, 0);
  };

  const computeMeanAndStd = (
    _samples: ISamples[],
    _listOfActiveSamples: string[],
  ) => {
    const activeSamples = _samples.filter((val) => {
      const isActiveSample = _listOfActiveSamples.includes(val._id as string);
      return isActiveSample;
    });
    const numSamples = activeSamples.length;

    let sumOfValues = [] as number[];
    let sumOfSquares = [] as number[];
    let numPixels: number = 0;

    if (numSamples > 0) {
      const firstElem = activeSamples[0].meta?.sumOfValues as number[];
      if (firstElem !== undefined) {
        firstElem.forEach(() => sumOfValues.push(0));
        firstElem.forEach(() => sumOfSquares.push(0));

        activeSamples.forEach((elem) => {
          const sumOfVal = elem.meta?.sumOfValues as number[];
          const sumOfSquare = elem.meta?.sumOfSquares as number[];
          const shape = elem.meta?.shape as number[];

          sumOfValues = sumOfValues.map((val, i) => val + sumOfVal[i]);
          sumOfSquares = sumOfSquares.map((val, i) => val + sumOfSquare[i]);
          numPixels += shape[0] * shape[1];
        });
        const mean = sumOfValues.map(val => val / numPixels);
        const std = sumOfSquares.map((val, i) => Math.sqrt((val / numPixels) - (mean[i] ** 2)));
        return [mean, std];
      }
    }
    return [[0], [0]];
  };

  const getMean = (_samples: ISamples[], _listOfActiveSamples: string[]) => {
    const mean = computeMeanAndStd(_samples, _listOfActiveSamples)[0];
    return mean;
  };

  const getStd = (_samples: ISamples[], _listOfActiveSamples: string[]) => {
    const std = computeMeanAndStd(_samples, _listOfActiveSamples)[1];
    return std;
  };

  const handleDownloadTagClick = async () => {
    const auth0token = await getTokenSilently();
    axios
      .get(`${SERVER_LOCATION}/users/datasets/${datasetId}/tags/${tag._id}/download`, {
        headers: {
          Authorization: `Bearer ${auth0token}`,
        },
      })
      .then((res: AxiosResponse) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        const fileName = `${tag.name}.txt`; // whatever your file name .
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove(); // you need to remove that elelment which is created before.
      });
  };

  return (
    <Grid
      container
      alignContent="stretch"
      direction="row"
      justify="flex-start"
      className={clsx(classes.spacing)}
    >
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" href="/datasets">
            My Datasets
          </Link>
          <Link color="inherit" href={`/datasets/${summary._id}`}>
            {summary.name}
          </Link>
          <Typography color="textPrimary">Download</Typography>
        </Breadcrumbs>
      </Grid>
      {tag.listOfSampleIds?.length as number > 0 && samples.length > 0 && samples[0].meta
        ? (
          <Grid item xs={12}>
            <Grid
              container
              alignContent="stretch"
              direction="row"
              justify="flex-start"
            >
              <Grid item xs={10} md={8} lg={6} xl={4}>
                <Grid
                  container
                  alignContent="stretch"
                  direction="column"
                  justify="space-between"
                >

                  <Grid item>
                    <h3>
                      {summary.name}
                      :
                      {tag.name}
                    </h3>
                  </Grid>
                  <Grid container direction="row" justify="space-between">
                    <Grid item>#samples</Grid>
                    <Grid item>
                      {tag.listOfSampleIds?.length as number}
                      {' / '}
                      {summary.nSamples}
                    </Grid>
                  </Grid>
                  <Grid container direction="row" justify="space-between">
                    <Grid item>Size in bytes</Grid>
                    <Grid item>
                      {displayBytes(computeSizeInBytes(samples, listOfActiveSamples))}
                      {' / '}
                      {displayBytes(summary.sizeInBytes)}
                    </Grid>
                  </Grid>
                  <Grid container direction="row" justify="space-between" className={clsx(classes.subHeading)}>
                    <Grid item>
                      <i>Mean and standard deviation over all pixels in tag of dataset</i>
                    </Grid>
                  </Grid>

                  <Grid container direction="row" justify="space-between">
                    <Grid item>Mean</Grid>
                    <Grid item>
                      {getMean(samples, listOfActiveSamples)
                        .map(val => val.toFixed(3))
                        .join(', ')}
                    </Grid>
                  </Grid>
                  <Grid container direction="row" justify="space-between">
                    <Grid item>Std</Grid>
                    <Grid item>
                      {getStd(samples, listOfActiveSamples)
                        .map(val => val.toFixed(3))
                        .join(', ')}
                    </Grid>
                  </Grid>
                  <Grid item className={clsx(classes.spacing)}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleDownloadTagClick}
                    >
                      Download Dataset
                    </Button>
                  </Grid>
                  <Grid item className={clsx(classes.spacing)}>
                    <p>
                      Hint: Use the following snippet on linux to copy the files.
                      Don&apos;t forget to adapt the destination directory:
                      {' '}
                      <b>dest_dir</b>
                    </p>
                    <p className={clsx(classes.code)}>
                      {'while IFS= read -r filename; do cp "$filename" '}
                      <b>dest_dir</b>
                      {`; done < ${tag.name}.txt`}
                    </p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={1} />
              <Grid item xs={10} md={4} xl={4}>
                <h5>Automatic preprocessing snippets</h5>
                <p className={clsx(classes.code)}>
                  # for data normalization in PyTorch
                  <br />
                  normalize = transforms.Normalize(
                  <br />
                  <span className={clsx(classes.codeIndent)}>
                    mean=[
                    {getMean(samples, listOfActiveSamples)
                      .map(val => val.toFixed(3))
                      .join(', ')}
                    ],
                  </span>
                  <br />
                  <span className={clsx(classes.codeIndent)}>
                    std=[
                    {getStd(samples, listOfActiveSamples)
                      .map(val => val.toFixed(3))
                      .join(', ')}
                    ])
                  </span>
                </p>
                <p className={clsx(classes.code)}>
                  # for data normalization in Fast.ai
                  <br />
                  my_dataset_stats = (
                  <br />
                  <span className={clsx(classes.codeIndent)}>
                    [
                    {getMean(samples, listOfActiveSamples)
                      .map(val => val.toFixed(3))
                      .join(', ')}
                    ],
                  </span>
                  <br />
                  <span className={clsx(classes.codeIndent)}>
                    [
                    {getStd(samples, listOfActiveSamples)
                      .map(val => val.toFixed(3))
                      .join(', ')}
                    ])
                  </span>
                </p>
              </Grid>
            </Grid>
          </Grid>
          ) : (
            <Grid item xs={10} md={8} lg={6} xl={4}>
              <p>
                No tag available to filter! Check whether your dataset has been
                {' '}
                uploaded properly and whether you created an initial tag.
              </p>
            </Grid>
          )}

    </Grid>
  );
};

export default DatasetDownload;
