/* eslint-disable no-underscore-dangle */
import React, { FunctionComponent, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import * as BPromise from 'bluebird';
import { useDropzone } from 'react-dropzone';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import axios from 'axios';
import { useAuth0 } from '../../contexts/auth0-context';
import { ISummary } from './DatasetSingle';
import { displayBytes, nameRegExpFormat, containsSpaceRegExpFormat } from '../../utils';
import {
  SERVER_LOCATION,
  MAX_SAMPLES_FRONTEND_UPLOAD,
  MIN_NAME_CHARACTERS,
  VALID_FILE_ENDINGS,
} from '../../constants';

const useStyles = makeStyles(() => ({
  copyright: {
    textAlign: 'right',
    padding: '0.5em',
  },
  version: {
    textAlign: 'left',
    padding: '0.5em',
  },
  spacing: {
    marginTop: 10,
  },
  text: {
    color: '#FFFFFF',
  },
  dropzone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignitems: 'center',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  },
  dropzoneText: {
    backgroundColor: '#EEE',
    padding: '20px',
    borderWidth: '2px',
    borderRadius: '2px',
    borderStyle: 'dashed',
    cursor: 'pointer',
  },
  expandText: {
    margin: 0,
  },
  code: {
    fontSize: '0.9em',
    fontFamily: 'monospace',
  },
  dialogTextfield: {
    minHeight: 80,
  },
  editNameIcon: {
    fontSize: '1.0em',
    paddingLeft: '5px',
    cursor: 'pointer',
  },
}));


interface IDatasetHomeProps {
    onDatasetSummaryNeedReloadChange: (ev: any) => void,
    onDatasetSamplesNeedReloadChange: (ev: any) => void,
    summary: ISummary,
    userToken: string,
    datasetSummaryNeedsReload: boolean,
    onDatasetTagsNeedsReload: () => void;
}

const DatasetHome: FunctionComponent<IDatasetHomeProps> = (props: IDatasetHomeProps) => {
  const classes = useStyles();

  const [files, setFiles] = useState([]);
  const [fileList, setFileList] = useState('');
  const [openUploadingDialog, setOpenUploadingDialog] = useState(false);
  const [dataIsUploading, setDataIsUploading] = useState(false);
  const [successfulUpload, setSuccessfulUpload] = useState(false);
  const [expandText, setExpandText] = useState('+');

  const [openChangeName, setOpenChangeName] = useState(false);
  const [datasetName, setDatasetName] = useState<string>('');
  const [datasetNameError, setDatasetNameError] = useState(false);
  const [datasetNameHelperText, setDatasetNameHelperText] = useState('');

  const [openBadFileTypeUsed, setOpenBadFileTypeUsed] = useState(false);

  const { datasetId } = useParams();
  const { isLoading, getTokenSilently } = useAuth0();
  const {
    summary,
    userToken,
    datasetSummaryNeedsReload,
    onDatasetSamplesNeedReloadChange,
    onDatasetSummaryNeedReloadChange,
    onDatasetTagsNeedsReload,
  } = props;

  const getPresignedUploadUrl = async (urlData: string): Promise<string> => {
    const auth0token = await getTokenSilently();
    const retVal = new Promise<string>((resolve) => {
      axios
        .request({
          method: 'post',
          url: `${SERVER_LOCATION}/users/datasets/${datasetId}/samples`,
          data: {
            sample: {
              fileName: urlData,
              isThumbnail: false,
            },
          },
          headers: {
            Authorization: `Bearer ${auth0token}`,
          },
        })
        .then((res) => {
          resolve(res.data.signedWriteUrl);
        })
        .catch((err) => {
          throw new Error(err);
        });
    });
    return retVal;
  };

  const uploadFile = async (url:any, file:any) => {
    const retVal = new Promise((resolve) => {
      axios
        .put(url, file, {
          headers: { 'content-type': 'application/octet-stream' },
        }).then(() => {
          resolve(file);
        });
    });
    return retVal;
  };

  const createInitialTag = async () => {
    const auth0token = await getTokenSilently();
    axios.post(`${SERVER_LOCATION}/users/datasets/${datasetId}/tags`, {}, {
      headers: {
        Authorization: `Bearer ${auth0token}`,
      },
    }).then((res) => {
      if (res.status !== 200) {
        console.log(res);
      }
      onDatasetTagsNeedsReload();
    });
  };

  const handleClickUpload = () => {
    if (files.length <= MAX_SAMPLES_FRONTEND_UPLOAD) {
      setOpenUploadingDialog(true);
      setDataIsUploading(true);
      setSuccessfulUpload(false);

      BPromise.Promise.map(files, (file: File) => getPresignedUploadUrl(file.name),
        { concurrency: 10 })
        .then((urls) => {
          BPromise.Promise.map(urls, (url: string, idx: number) => uploadFile(url, files[idx]),
            { concurrency: 10 })
            .then(() => {
              setFiles([]);
              setFileList('');
              setTimeout(() => {
                setDataIsUploading(false);
                setSuccessfulUpload(true);
              }, 3000 * Math.log(20 + urls.length / 20));
            });
        });
    } else {
      alert(`Your dataset has ${files.length} samples which is more than the maximum 
of ${MAX_SAMPLES_FRONTEND_UPLOAD} you can upload through the front-end. 
Please use the CLI for more than ${MAX_SAMPLES_FRONTEND_UPLOAD} files.`);
    }
  };

  const handleClickReset = () => {
    setFiles([]);
    setExpandText('+');
    setFileList('');
    setOpenUploadingDialog(false);
    setDataIsUploading(false);
    setSuccessfulUpload(false);
  };

  const validFileType = (file: File) => {
    const fileEnding = file.name.split('.').pop();
    if (!fileEnding) {
      return false;
    }
    const valid = VALID_FILE_ENDINGS.includes(fileEnding.toLowerCase());
    if (!valid) {
      setOpenBadFileTypeUsed(true);
    }
    return valid;
  };

  const handleOnDrop = (newFiles: File []) => {
    const currentFiles = files as File[];

    const newFilteredFiles = newFiles.filter(file => validFileType(file));
    newFilteredFiles.map(file => currentFiles.push(file));

    const newFileList = currentFiles.map((file: File) => (
      <li key={file.name}>
        {file.name}
        {' '}
        -
        {' '}
        {file.size}
        {' '}
        bytes
      </li>
    )) as any;
    setFileList(newFileList);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: droppedFiles => handleOnDrop(droppedFiles),
  });

  const handleSuccessfulUploadDialogOk = () => {
    setSuccessfulUpload(false);
    setOpenUploadingDialog(false);
    onDatasetSummaryNeedReloadChange(true);
    onDatasetSamplesNeedReloadChange(true);
    createInitialTag();
  };

  const handleClickCloseUploadingDialog = () => {
    setOpenUploadingDialog(false);
  };

  const handleExpandClick = () => {
    if (expandText === '+') {
      setExpandText('-');
    } else {
      setExpandText('+');
    }
  };

  const handleChangeDatasetName = () => {
    setDatasetName(summary.name);
    setOpenChangeName(true);
  };

  const handleChangeNameCancel = () => {
    setOpenChangeName(false);
  };

  const validDatasetName = (name: string) => {
    if (name.length > MIN_NAME_CHARACTERS && !nameRegExpFormat.test(name)) {
      return true;
    }
    setDatasetNameError(true);
    if (name.length <= MIN_NAME_CHARACTERS) {
      setDatasetNameHelperText('Dataset name requires at least 3 characters!');
    } else if (containsSpaceRegExpFormat.test(name)) {
      setDatasetNameHelperText('Whitespaces are not allowed in dataset name!');
    } else {
      setDatasetNameHelperText('No special characters except `-` and `_` are allowed!');
    }
    return false;
  };

  const datasetNameChange = (event: any) => {
    const name = event.target.value as string;
    setDatasetName(event.target.value);
    if (validDatasetName(name)) {
      setDatasetNameError(false);
      setDatasetNameHelperText('');
    }
  };

  const handleChangeNameDialogClick = async () => {
    // create dataset request
    const name = datasetName;
    if (validDatasetName(name)) {
      setDatasetNameError(false);
      const auth0token = await getTokenSilently();
      axios
        .request({
          method: 'put',
          url: `${SERVER_LOCATION}/users/datasets/${summary._id}`,
          data: { dataset: { name } },
          headers: {
            Authorization: `Bearer ${auth0token}`,
          },
        })
        .then(() => {
          setOpenChangeName(false);
          onDatasetSummaryNeedReloadChange(true);
        });
    }
  };

  const handleClickCloseBadFileTypeDialog = () => {
    setOpenBadFileTypeUsed(false);
  };

  const handleChangeNameKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      handleChangeNameDialogClick();
    }
  };

  return (
    <div>
      <Grid
        container
        alignContent="stretch"
        direction="row"
        justify="flex-start"
        className={clsx(classes.spacing)}
      >
        <Grid item xs={12}>
          { summary.name && (
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" href="/datasets">
                My Datasets
              </Link>
              <Typography color="textPrimary">{summary.name}</Typography>
            </Breadcrumbs>
          )}
        </Grid>
        {summary.name === '' && <CircularProgress />}
        {(summary.nSamples as number) > 0
          ? !isLoading && !datasetSummaryNeedsReload && (
          <Grid item xs={10}>
            <Grid
              container
              alignContent="stretch"
              direction="row"
            >
              <Grid item xs={8} lg={6} xl={4}>
                <Grid
                  container
                  alignContent="stretch"
                  direction="column"
                  justify="space-between"
                >
                  <Grid container direction="row" justify="space-between">
                    <Grid item>
                      <h3>
                        {summary.name}
                        <EditIcon
                          className={clsx(classes.editNameIcon)}
                          onClick={handleChangeDatasetName}
                        />
                      </h3>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" justify="space-between">
                    <Grid item>
                      <h4>Summary of full dataset</h4>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" justify="space-between">
                    <Grid item>Type</Grid>
                    <Grid item>{summary.type}</Grid>
                  </Grid>
                  <Grid container direction="row" justify="space-between">
                    <Grid item>#samples</Grid>
                    <Grid item>{summary.nSamples}</Grid>
                  </Grid>
                  <Grid container direction="row" justify="space-between">
                    <Grid item>Size</Grid>
                    <Grid item>{displayBytes(summary.sizeInBytes)}</Grid>
                  </Grid>
                  <Grid container direction="row" justify="space-between">
                    <Grid item>Dataset ID</Grid>
                    <Grid item>{summary._id}</Grid>
                  </Grid>
                  <Grid container direction="row" justify="space-between">
                    <Grid item>Created at</Grid>
                    <Grid item>
                      {moment(
                        (summary.createdAt as any),
                      ).format('D.M.YYYY h:mm:ss a')}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4} />
            </Grid>
          </Grid>
          )
          : !isLoading && !datasetSummaryNeedsReload && (
          <Grid item xs={10}>
            <Grid item>
              <p>
                Use the following command to upload the dataset from your
                {' '}
                <a
                  href="https://pypi.org/project/borisml/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  borisml CLI
                </a>
                {'. '}
                To create a new api token click on the top right corner
                {' '}
                on your user e-mail and go to the preferences menu.
              </p>
              <p className={clsx(classes.code)}>
                boris-upload path_to_folder=&apos;/PATH/TO/IMGS/&apos; token=&apos;
                {userToken}
                &apos; dataset_id=&apos;
                {datasetId}
                &apos;
              </p>
              <p>or</p>
              <p>use the following web interface</p>
            </Grid>
            <Grid item>
              <section className={clsx(classes.dropzone)}>
                <div
                  {...getRootProps({ className: 'dropzone' })}
                  className={clsx(classes.dropzoneText)}
                >
                  <input {...getInputProps()} />
                  <p>The current dataset is empty.</p>
                  <p>
                    Drag &apos;n&apos; drop some image files here, or click to
                    select files. Supported file types include
                    {' '}
                    {VALID_FILE_ENDINGS.join(', ')}
                  </p>
                  <p>
                    We recommend to not upload more than 1000
                    samples or 1GB via this web interface. For larger datasets, you should
                    use our pip package.
                  </p>
                  <p>
                    The maximum supported image width and height are 2048 pixels!
                  </p>
                </div>
              </section>
              {fileList !== '' && (
                <div>
                  <Button
                    onClick={handleClickUpload}
                    variant="outlined"
                    color="primary"
                    className={clsx(classes.spacing)}
                  >
                    Click to upload dataset
                  </Button>
                  <Button
                    onClick={handleClickReset}
                    variant="outlined"
                    color="primary"
                    className={clsx(classes.spacing)}
                  >
                    Reset
                  </Button>
                  <aside>
                    <Button onClick={handleExpandClick}>
                      <h4 className={clsx(classes.expandText)}>
                        Files
                        {fileList.length > 0 && (` (${fileList.length}) `)}
                        {expandText}
                      </h4>
                    </Button>
                    {expandText === '-' && <ul>{fileList}</ul>}
                  </aside>
                </div>
              )}
            </Grid>
          </Grid>
          )}
      </Grid>
      <Dialog
        open={openBadFileTypeUsed}
        onClose={handleClickCloseBadFileTypeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Invalid file type</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The web upload supports only the following file types include
            {' '}
            {VALID_FILE_ENDINGS.join(', ')}
          </DialogContentText>
          {dataIsUploading && <CircularProgress />}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClickCloseBadFileTypeDialog}
            variant="outlined"
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openUploadingDialog}
        onClose={handleClickCloseUploadingDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Uploading new samples</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please wait until the upload of the new samples completes. This
            process can take several minutes.
          </DialogContentText>
          {dataIsUploading && <CircularProgress />}
        </DialogContent>
        <DialogActions>
          {!dataIsUploading && (
            <Button
              onClick={handleSuccessfulUploadDialogOk}
              variant="outlined"
              color="primary"
              disabled={!successfulUpload}
            >
              OK
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Dialog open={openChangeName} onClose={handleChangeNameCancel} onKeyPress={handleChangeNameKeyPress} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create new dataset</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter a name for your new dataset. Only alphanumeric characters,
            {' '}
            `-` and `_` are allowed. Whitespaces are not allowed!
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            value={datasetName}
            onChange={datasetNameChange}
            fullWidth
            error={datasetNameError}
            helperText={datasetNameHelperText}
            className={clsx(classes.dialogTextfield)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleChangeNameCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleChangeNameDialogClick} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DatasetHome;
