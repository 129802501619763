/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  FunctionComponent, useEffect, useState, useRef,
} from 'react';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import * as d3 from 'd3';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles(() => ({
  copyright: {
    textAlign: 'right',
    padding: '0.5em',
  },
  version: {
    textAlign: 'left',
    padding: '0.5em',
  },
  spacing: {
    width: 'inherit',
  },
  text: {
    color: '#FFFFFF',
  },

  histogramContainer: {
    display: 'inline-block',
    position: 'relative',
    width: '100%',
  },
}));

export interface IHistogram {
    title: string,
    data: number[],
    histValues?: number[],
    minVal?: number,
    maxVal?: number
    onSliderValChange: (ev: number[]) => void,
}

function computeHistogram(data: number[], title: string, nBins: number = 16): IHistogram {
  // const hist = inputData;
  const minVal = Math.min(...data);
  const maxVal = Math.max(...data);
  const binOffset = (maxVal - minVal) / nBins;
  const histValues = [...Array(nBins)].map(() => 0);

  for (let i = 0; i < data.length; i += 1) {
    let bucketIdx = 0;
    if (data[i] === maxVal) {
      bucketIdx = nBins - 1;
    } else {
      bucketIdx = Math.floor((data[i] - minVal) / binOffset);
    }
    histValues[bucketIdx] += 1;
  }

  const hist = {
    title,
    data,
    histValues,
    minVal,
    maxVal,
  } as IHistogram;
  hist.data.map(d => d + 1);

  return hist;
}

const HistogramComponent: FunctionComponent<IHistogram> = (props: IHistogram) => {
  const classes = useStyles();
  const ref = useRef(null);

  const [sliderValue, setSliderValue] = useState<number[]>([0, 1]);
  const [sliderStep, setSliderStep] = useState(1);

  const w = 200;
  const h = 100;
  const margin = 20;

  const [histData, setHistData] = useState<IHistogram>(props);

  const { data, onSliderValChange, title } = props;

  useEffect(() => {
    if (data.length > 0) {
      const nBins = data.length < 100 ? 16 : 32;
      const newHistData = computeHistogram(data, title, nBins);
      setHistData(newHistData);
      const minV = newHistData.minVal as number;
      const maxV = newHistData.maxVal as number;
      setSliderValue([minV, maxV]);
      setSliderStep((maxV - minV) / 50);
      onSliderValChange([minV, maxV]);
    }
  }, [data]);

  useEffect(() => {
    const { histValues, minVal, maxVal } = histData;

    let g = d3.select(ref.current);
    g.selectAll('g').remove();
    g.selectAll('rect').remove();
    g.selectAll('text').remove();

    g = d3.select(ref.current)
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr('viewBox', '0 0 200 100')
      .classed('svg-content', true);

    if (histValues !== undefined && minVal && maxVal) {
      const barSpacing = 3;
      const barWidth = ((w - barSpacing) / histValues.length);

      const sliderOverlayScale = d3.scaleLinear()
        .domain([minVal as number, maxVal as number])
        .range([0, w]);

      g.append('rect')
        .attr('y', margin)
        .attr('x', sliderOverlayScale(sliderValue[0]))
        .attr('width', sliderOverlayScale(sliderValue[1]) - sliderOverlayScale(sliderValue[0]))
        .attr('height', h)
        .attr('fill', '#EBEBEB');

      const yScale = d3.scaleLinear()
        .domain([0, d3.max(histValues) as number])
        .range([0, h - 2 * margin - barSpacing]);

      g.selectAll('.g')
        .data(histValues)
        .enter()
        .append('rect')
        .attr('y', d => h - yScale(d))
        .attr('height', d => yScale(d))
        .attr('width', barWidth - barSpacing)
        .attr('fill', '#C4C4C4')
        .attr('transform', (d, i) => {
          const translate = [barSpacing + barWidth * i, -margin];
          return `translate(${translate})`;
        });

      g.append('text')
        .attr('x', (w / 2))
        .attr('y', 15)
        .attr('text-anchor', 'middle')
        .style('font-size', '14px')
        .style('text-decoration', 'underline')
        .text(title);

      // Create scale
      const scale = d3.scaleLinear()
        .domain([minVal as number, maxVal as number])
        .range([0, w]);
        // Add scales to axis
      const xAxis = d3.axisBottom(scale);

      // Append group and insert axis
      d3.select(ref.current)
        .append('g')
        .attr('transform', `translate(0, ${h - 20})`)
        .call(xAxis.ticks(5, 's'));
    }
  }, [histData, sliderValue]);

  const handleChange = (event: any, newValue: number | number[]) => {
    setSliderValue(newValue as number[]);
    onSliderValChange(newValue as number[]);
  };

  return (
    <Grid
      container
      alignContent="stretch"
      direction="column"
      justify="space-between"
    >
      {data.length > 0 && (
        <Grid item>
          <Grid item>
            <svg ref={ref} className={clsx(classes.histogramContainer)} />
          </Grid>
          <Grid item>
            <Slider
              value={sliderValue}
              onChange={handleChange}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
              min={Math.min.apply(Math, histData.data)}
              max={Math.max.apply(Math, histData.data)}
              step={sliderStep}
              valueLabelFormat={(x: number) => x.toFixed(2)}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const Histogram = React.memo(HistogramComponent);

export default Histogram;
